import * as React from "react"
import { useStyletron } from "baseui"
import { Checkbox } from "baseui/checkbox"
import { Option } from "baseui/select"
import { LabelLarge, LabelMedium } from "baseui/typography"
import moment from "moment-timezone"
import { useMutation, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"
import { Prompt, useHistory } from "react-router-dom"
import { PortalContainer } from "../../controllers/portal"
import { fetching } from "../../fetching"
import { emailRegex } from "../../helpers/utils"
import { ImageUpload } from "../../pages/admin/imageUpload"
import { minimumAgeMonths, PronounSection } from "../../types/enums"
import { atsiOption, BasicName, ClientDetail, Country, Gender, HowDoYouHearAboutUs, Language, residencyStatus, ResponseOptions } from "../../types/types"
import { CancelAndSaveButtons } from "../cancelSaveButtons"
import { ErrorNotification } from "../errorBox"
import { useZenToast } from "../zenComponents/useZenToast"
import { ZenPostalAddress } from "../zenComponents/zenAddress"
import { ZenCheckbox } from "../zenComponents/zenCheckboxList"
import { ZenDivider, ZenInput, ZenTextArea } from "../zenComponents/zenInput"
import { ZenPlaceSelect, ZenSelect } from "../zenComponents/zenSelectBox"
import { ZenDatePicker } from "../zenComponents/zenTime"
import { ServiceCheckTable } from "./clientServiceCheckTable"
import { ErrorFieldTracker } from "../forms/errorFieldTracker"
import { responseOptions } from "./clientCreateForm"
import { RadioGroup, Radio } from 'baseui/radio';

interface ClientEditFormProps {
	client: ClientDetail
}

const checkboxOptions = [
	{ id: 'YES', label: 'Yes' },
	{ id: 'NO', label: 'No' },
];

export const ClientEditForm = (props: ClientEditFormProps) => {
	const { client } = props
	const [css] = useStyletron()
	const { showToast } = useZenToast()
	const { fetchClient, clientLoading } = PortalContainer.useContainer()
	const [avatar, setAvatar] = React.useState<File>()
	const [sameAsResidentialAddress, setSameAsResidentialAddress] = React.useState<boolean>(!client.currentContact?.postalAddress)
	const [manualPostalAddress, setManualPostalAddress] = React.useState(false)
	const [countryOptions, setCountryOptions] = React.useState<Option[]>([])
	const [languageOptions, setLanguageOptions] = React.useState<Option[]>([])
	const [contactList, setContactList] = React.useState<Option[]>([])
	const [serviceList, setServiceList] = React.useState<BasicName[]>([])
	const [contactError, setContactError] = React.useState<boolean>(false)
	const [pronounSection, setPronounSection] = React.useState<string>("")
	const pronounOptionArr = [
		{ id: PronounSection.HeHimHis, label: "He/Him/His" },
		{ id: PronounSection.SheHer, label: "She/Her" },
		{ id: PronounSection.TheyThem, label: "They/Them" },
	]
	const pronounOptionMemo = React.useMemo(() => {
		return !!pronounSection ?
			[
				{ id: pronounSection, label: pronounSection },
				...pronounOptionArr
			]
			: pronounOptionArr
	}, [pronounSection]);

	// mutation function
	const { mutate: clientUpdate, error, payload, loading } = useMutation(fetching.mutation.clientUpdate)
	const fileUpload = useMutation<{ fileID: string }>(fetching.mutation.fileUpload)

	// load select options
	const countryData = useQuery<Country[]>(fetching.query.getCountryAll())
	const languageData = useQuery<Language[]>(fetching.query.getLanguageAll())
	const initialContactSourceData = useQuery<BasicName[]>(fetching.query.getInitialContactSourceAll())
	const serviceProviderData = useQuery<BasicName[]>(fetching.query.getServiceProviderAll())
	const genderData = useQuery<Gender[]>(fetching.query.getGenderAll())
	const history = useHistory()

	const { errors, control, setValue, handleSubmit, watch, formState } = useForm()

	const canLeaveVoice = !!watch("telephoneNumber")
	const canLeaveText = !!watch("mobileNumber")
	const canSendEmail = !!watch("email")
	const serviceProvider = watch("serviceProvider")

	const [isOrganisation, setIsOrganisation] = React.useState(false)

	React.useEffect(() => {
		const genderID = client.genderID
		if (!genderID || !genderData.payload) return

		const gender = genderData.payload.find((g) => g.id === genderID)

		if (!gender) return

		setValue("genderID", [gender])
	}, [client, genderData.payload, setValue])

	// Prepare form data for Marketing
	const [explainedServiceIDs, setExplainedServiceIDs] = React.useState<string[]>([])
	const [interestedServiceIDs, setInterestedServiceIDs] = React.useState<string[]>([])

	// build select options
	const [atsiEntries] = React.useState(Object.entries(atsiOption))
	const [residencyStatusOptions] = React.useState(Object.entries(residencyStatus))
	React.useEffect(() => {
		if (countryData.loading || !countryData.payload) return
		setCountryOptions(countryData.payload.map<Option>((c) => ({ label: c.countryName, id: c.id })))
	}, [countryData.loading, countryData.payload])
	React.useEffect(() => {
		if (languageData.loading || !languageData.payload) return
		const newLanguageData = languageData.payload.map<Option>((l) => ({
			label: l.label,
			id: l.id,
		}))
		newLanguageData.sort((a, b) => {
			return a.label === "English" ? -1 : b.label === "English" ? 1 : 0
		})
		setLanguageOptions(newLanguageData)
	}, [languageData.loading, languageData.payload])
	React.useEffect(() => {
		if (initialContactSourceData.loading || !initialContactSourceData.payload) return
		setContactList(initialContactSourceData.payload)
	}, [initialContactSourceData.loading, initialContactSourceData.payload])
	React.useEffect(() => {
		if (serviceProviderData.loading || !serviceProviderData.payload) return
		setServiceList(serviceProviderData.payload)
	}, [serviceProviderData.loading, serviceProviderData.payload])

	// pre-fill the existing data
	React.useEffect(() => {
		setIsOrganisation(client.isOrganisation)
		setValue("firstName", client.firstName)
		setValue("lastName", client.lastName)
		setValue("preferredName", client.preferredName)
		setValue(
			"residentialAddress",
			client.currentContact?.residentialAddress
				? [{ id: client.currentContact?.residentialAddress.placeID, label: client.currentContact?.residentialAddress.fullAddress }]
				: [],
		)
		setValue(
			"postalAddress",
			client.currentContact?.postalAddress?.placeID
				? [{ id: client.currentContact?.postalAddress.placeID, label: client.currentContact?.postalAddress.fullAddress }]
				: [],
		)
		if (!!client.currentContact?.postalAddress && !client.currentContact?.postalAddress.placeID) {
			setValue("manualPostalAddress", {
				...client.currentContact.postalAddress,
			})
			setManualPostalAddress(true)
		}
		setValue("telephoneNumber", client.currentContact?.telephoneNumber)
		setValue("mobileNumber", client.currentContact?.mobileNumber)
		setValue("email", client.currentContact?.email)
		setValue("whereClientHearAboutUs", client.howDidYouHearAboutUs ? [{ id: client.howDidYouHearAboutUs, label: client.howDidYouHearAboutUs }] : [])

		const atsi = atsiEntries.find((a) => a[0] === client.aboriginalOrTorresStraitIslander)
		if (atsi) {
			setValue("aboriginalOrTorresStraitIslander", [{ id: atsi[0], label: atsi[1] }])
		}

		const residencyState = residencyStatusOptions.find((rs) => rs[0] === client?.residencyStatus)
		if (residencyState) {
			setValue("residencyStatus", [{ id: residencyState[0], label: residencyState[1] }])
		}

		setValue("leaveVoiceMessage", client.leaveVoiceMessage)
		setValue("leaveTextMessage", client.leaveTextMessage)
		setValue("agreedToReceiveEmails", client.agreedToReceiveEmails)
		setValue("culturallyAndLinguisticallyDiverse", client.culturallyAndLinguisticallyDiverse)
		setValue("lesbianGayBisexualAndTransgender", client.lesbianGayBisexualAndTransgender)
		setValue("receiveUpcomingEvent", client.receiveUpcomingEvent)
		setValue("requiresTranslator", client.requiresTranslator)
		setValue("receiveUpcomingEvent", client.receiveUpcomingEvent)

		setValue(
			"countryOfBirthID",
			client?.countryOfBirth
				? [
					{
						id: client?.countryOfBirth?.id,
						label: client?.countryOfBirth?.countryName,
					},
				]
				: [],
		)
		setValue(
			"languageSpokenAtHomeID",
			client?.languageSpokenAtHome
				? [
					{
						id: client?.languageSpokenAtHome?.id,
						label: client?.languageSpokenAtHome?.label,
					},
				]
				: [],
		)

		setValue("initialContactSource", client.initialContactSource ? [{ ...client.initialContactSource, label: client.initialContactSource.name }] : [])
		setValue("serviceProvider", client.serviceProvider ? [{ ...client.serviceProvider, label: client.serviceProvider.name }] : [])
		setValue("customServiceProvider", client.customServiceProvider)
		setValue("helpLookingFor", client.helpLookingFor)
		setExplainedServiceIDs(client.relatedService.explainedServiceList)
		setInterestedServiceIDs(client.relatedService.interestedServiceList)
		const val1 = responseOptions.find((f) => f.id === client.currentlyCaringForNdisPlan)
		const val2 = responseOptions.find((f) => f.id === client.hasAppliedAndDeclinedNdisPlan)
		setValue("currentlyCaringForNdisPlan", val1 ? [{ ...val1, label: val1?.label }] : [])
		setValue("hasAppliedAndDeclinedNdisPlan", val2 ? [{ ...val2, label: val2?.label }] : [])
		const pronounOptionMap: { [key: string]: PronounSection } = {
			"He/Him/His": PronounSection.HeHimHis,
			"She/Her": PronounSection.SheHer,
			"They/Them": PronounSection.TheyThem,
		};
		const pronounOptionID = client.pronounOption == pronounOptionMap[client.pronounOption] ? pronounOptionMap[client.pronounOption] : !!client.pronounOption ? client.pronounOption : undefined;
		setValue("pronounSection", !!client.pronounOption ? [{ id: pronounOptionID, label: client.pronounOption }] : [])
	}, [atsiEntries, client, residencyStatusOptions, setValue])

	// Check contact methods exist
	React.useEffect(() => {
		// client contact method
		const hasContact = canLeaveVoice || canLeaveText || canSendEmail
		if (!hasContact) {
			setContactError(true)
			return
		}
		setContactError(false)
	}, [canLeaveText, canLeaveVoice, canSendEmail])

	const back = () => {
		history.push(`/portal/clients/${props.client.id}`)
	}

	const onSubmit = async (formData: any) => {
		// upload avatar
		let avatarID: string | undefined = undefined
		if (avatar) {
			const resp = await fileUpload.mutate({ file: avatar })
			if (resp.error || !resp.payload) return
			avatarID = resp.payload.fileID
		}

		const postalAddress = () => {
			if (sameAsResidentialAddress) return undefined
			if (manualPostalAddress) {
				return {
					postalAddressLine1: formData.manualPostalAddress.addressLine1,
					postalAddressLine2: formData.manualPostalAddress.addressLine2,
					postalSuburb: formData.manualPostalAddress.suburb,
					postalState: formData.manualPostalAddress.state,
					postalPostalCode: formData.manualPostalAddress.postalCode,
				}
			}
			if (formData.postalAddress && formData.postalAddress.length > 0) {
				return { postalAddressPlaceID: formData.postalAddress[0].id }
			}
			return undefined
		}

		// client contact method
		const hasContact = canLeaveVoice || canLeaveText || canSendEmail
		if (!hasContact) {
			setContactError(!hasContact)
			return
		}
		// start updating
		const updateResp = await clientUpdate({
			id: client.id,
			avatarID,
			firstName: formData.firstName,
			lastName: formData.lastName,
			preferredName: formData.preferredName,
			dateOfBirth: formData.dateOfBirth,
			leaveVoiceMessage: formData.leaveVoiceMessage,
			leaveTextMessage: formData.leaveTextMessage,
			agreedToReceiveEmails: formData.agreedToReceiveEmails,
			contact: {
				residentialAddressPlaceID: formData.residentialAddress && formData.residentialAddress.length > 0 ? formData.residentialAddress[0].id : undefined,
				postalAddress: postalAddress(),
				telephoneNumber: formData.telephoneNumber,
				mobileNumber: formData.mobileNumber,
				email: formData.email,
			},
			// additional information
			countryOfBirthID: formData.countryOfBirthID && formData.countryOfBirthID.length > 0 ? formData.countryOfBirthID[0].id : undefined,
			languageSpokenAtHomeID: formData.languageSpokenAtHomeID && formData.languageSpokenAtHomeID.length > 0 ? formData.languageSpokenAtHomeID[0].id : undefined,
			requiresTranslator: formData.requiresTranslator,
			residencyStatus: formData.residencyStatus && formData.residencyStatus.length > 0 ? formData.residencyStatus[0].id : undefined,
			genderID: formData.genderID && formData.genderID.length > 0 ? formData.genderID[0].id : undefined,
			culturallyAndLinguisticallyDiverse: formData.culturallyAndLinguisticallyDiverse,
			lesbianGayBisexualAndTransgender: formData.lesbianGayBisexualAndTransgender,
			aboriginalOrTorresStraitIslander:
				formData.aboriginalOrTorresStraitIslander && formData.aboriginalOrTorresStraitIslander.length > 0
					? formData.aboriginalOrTorresStraitIslander[0].id
					: undefined,

			// marketing information
			receiveUpcomingEvent: formData.receiveUpcomingEvent,
			whereClientHearAboutUs:
				formData.whereClientHearAboutUs && formData.whereClientHearAboutUs.length > 0 ? formData.whereClientHearAboutUs[0].label : undefined,
			initialContactSourceID: formData.initialContactSource && formData.initialContactSource.length > 0 ? formData.initialContactSource[0].id : undefined,
			helpLookingFor: formData.helpLookingFor,
			serviceProviderID: formData.serviceProvider && formData.serviceProvider.length > 0 ? formData.serviceProvider[0].id : undefined,
			customServiceProvider: formData.customServiceProvider,
			interestedServiceIDs: interestedServiceIDs,
			explainedServiceIDs: explainedServiceIDs,
			currentlyCaringForNdisPlan: !formData.isOrganisation && formData.currentlyCaringForNdisPlan && formData.currentlyCaringForNdisPlan.length > 0 ? formData.currentlyCaringForNdisPlan[0].id : undefined,
			hasAppliedAndDeclinedNdisPlan: !formData.isOrganisation && formData.hasAppliedAndDeclinedNdisPlan && formData.hasAppliedAndDeclinedNdisPlan.length > 0 ? formData.hasAppliedAndDeclinedNdisPlan[0].id : undefined,
			pronounSection: formData.pronounSection?.length > 0 ? formData.pronounSection[0]?.id : !!pronounSection ? pronounSection : undefined,
		})

		if (updateResp.error || !updateResp.payload) return
		await fetchClient(client.id)
		showToast("Client updated successfully.", "positive")
		history.push(`/portal/clients/${client.id}`)
	}
	const container = css({
		height: "100%",
		width: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	})
	const title = css({
		width: "100%",
		display: "flex",
	})
	const scrollDiv = css({
		width: "100%",
		paddingRight: "10px",
		overflowX: "hidden",
		overflowY: "auto",
	})
	const group = css({
		display: "flex",
		alignItems: "center",
		marginTop: "10px",
		"@media only screen and (max-width: 700px)": {
			flexDirection: "column",
			alignItems: "center",
		},
	})
	const avatarContainer = css({
		width: "225px",
		display: "flex",
	})
	const avatarContainerInner = css({
		width: "100%",
		height: "173px",
	})
	const nameContainer = css({
		width: "100%",
		marginLeft: "15px",
		"@media only screen and (max-width: 700px)": {
			marginLeft: 0,
		},
	})

	return (
		<>
			<form autoComplete="off" className={container} onSubmit={handleSubmit(onSubmit)}>
				<div className={title}>
					<LabelLarge>Client Details</LabelLarge>
				</div>
				<ZenDivider />
				<div className={scrollDiv}>
					{/* Basic Detail*/}
					<LabelMedium marginTop="5px">General</LabelMedium>

					<div className={group}>
						<div className={avatarContainer}>
							<div className={avatarContainerInner}>
								<ImageUpload
									label="Avatar"
									name="avatar"
									imageURL={client.avatarURL || ""}
									imageRemoved={false}
									file={avatar}
									setFile={(file?: File) => setAvatar(file)}
									previewHeight="200px"
									maxFileSize={1e7}
								/>
							</div>
						</div>
						<div className={nameContainer}>
							<ZenInput label={isOrganisation ? "Name" : "First name"} nameRef="firstName" inputError={errors.firstName} formRef={control} required />
							{!isOrganisation && <ZenInput label="Last name" nameRef="lastName" inputError={errors.lastName} formRef={control} required />}
							<ZenInput label="Preferred name" nameRef="preferredName" formRef={control} />
						</div>
					</div>

					{!isOrganisation && (
						<>
							<ZenSelect label={"Gender"} formName={"genderID"} formRef={control} options={genderData.payload || []} isLoading={genderData.loading} />
							<ZenSelect
								label={"Pronoun"}
								formName={"pronounSection"}
								placeholder="Select..."
								formRef={control}
								inputError={errors.pronounSection}
								onInputChange={(e: any) => setPronounSection(e.target.value)}
								options={pronounOptionMemo}
							/>
							<ZenDatePicker
								label="Date of birth"
								formName="dateOfBirth"
								formRef={control}
								nullDefaultValue
								value={client.dateOfBirth ? moment(client.dateOfBirth).toDate() : undefined}
								minDate={new Date("1900-01-01")}
								maxDate={moment().subtract(minimumAgeMonths, "month").toDate()}
							/>
						</>
					)}

					<LabelMedium marginTop="25px">Contacts</LabelMedium>
					<ZenPlaceSelect label="Residential Address" formName="residentialAddress" formRef={control} placeholder="Address here" />
					<Checkbox checked={sameAsResidentialAddress} onChange={() => setSameAsResidentialAddress((e) => !e)}>
						Postal address is same as residential address
					</Checkbox>
					{!sameAsResidentialAddress && (
						<ZenPostalAddress
							label="Postal Address"
							formName="postalAddress"
							formNameManual="manualPostalAddress"
							formRef={control}
							placeholder="Address here"
							manualAddress={manualPostalAddress}
							setManualAddress={setManualPostalAddress}
						/>
					)}
					<div id="clientContact" />
					{contactError && <ErrorNotification message="One contact method is required" kind="warning" />}
					<ZenInput
						// Use generic input to allow for more flexible usage, such as a note about the number.
						// Not required because primary contact could be an perant or gardian
						marginTop="15px"
						label="Telephone"
						placeholder="Enter telephone"
						nameRef="telephoneNumber"
						inputError={errors.telephoneNumber}
						formRef={control}
					/>
					{canLeaveVoice && (
						<ZenCheckbox
							label="Can we leave a voice message?"
							labelPlacement="left"
							formName="leaveVoiceMessage"
							formRef={control}
							marginBottom="10px"
							marginTop="5px"
						/>
					)}
					<ZenInput
						// Use generic input to allow for more flexible usage, such as a note about the number.
						// Not required because primary contact could be an perant or gardian
						marginTop="15px"
						label="Mobile"
						nameRef="mobileNumber"
						inputError={errors.mobileNumber}
						formRef={control}
					/>
					{canLeaveText && (
						<ZenCheckbox
							label="Can we send a text message?"
							labelPlacement="left"
							formName="leaveTextMessage"
							formRef={control}
							marginBottom="10px"
							marginTop="5px"
						/>
					)}
					<ZenInput
						marginTop="15px"
						label="Email"
						nameRef="email"
						formRef={control}
						formRules={{
							validate: {
								required: (value: string) => {
									if (value) {
										let re = new RegExp(emailRegex)
										if (!re.test(value)) {
											return "Invalid email"
										}
									}
									return true
								},
							},
						}}
					/>
					{canSendEmail && (
						<ZenCheckbox
							label="Can we send emails?"
							labelPlacement="left"
							formName="agreedToReceiveEmails"
							formRef={control}
							marginBottom="10px"
							marginTop="5px"
						/>
					)}

					{!isOrganisation && (
						<>
							{/* Additional information */}
							<LabelMedium marginBottom="15px" marginTop="25px">
								Additional information
							</LabelMedium>
							<ZenSelect
								label="Country of Birth"
								formName="countryOfBirthID"
								formRef={control}
								clearable={false}
								options={countryOptions}
								isLoading={countryData.loading}
							/>
							<ZenSelect
								label="Language Spoken at Home"
								formName="languageSpokenAtHomeID"
								formRef={control}
								clearable={false}
								options={languageOptions}
								isLoading={languageData.loading}
							/>
							<ZenCheckbox label="Translator Required" formName="requiresTranslator" formRef={control} marginTop="5px" />
							<ZenSelect
								label="Residency Status"
								formName="residencyStatus"
								formRef={control}
								clearable={false}
								options={residencyStatusOptions.map((r) => ({ label: r[1], id: r[0] }))}
							/>
							<ZenSelect
								label="Identify as ATSI"
								formName="aboriginalOrTorresStraitIslander"
								formRef={control}
								clearable={false}
								options={atsiEntries.map((a) => ({ label: a[1], id: a[0] }))}
							/>
							<div className={group}>
								<ZenCheckbox label="Identify as CaLD" formName="culturallyAndLinguisticallyDiverse" formRef={control} width="100%" />
								<ZenCheckbox label="Identify as LGBTIQ+" formName="lesbianGayBisexualAndTransgender" formRef={control} width="100%" />
							</div>
						</>
					)}

					{/* Supplementary Section*/}
					<LabelMedium marginTop="25px" marginBottom="10px">
						Marketing
					</LabelMedium>
					<LabelMedium marginBottom="5px">
						Would you like to stay updated on upcoming HelpingMinds' information and events via email?
					</LabelMedium>
					<RadioGroup
						name="receiveUpcomingEvent"
						onChange={(e) => setValue('receiveUpcomingEvent', e.target.value)}
						value={watch('receiveUpcomingEvent')}
					>
						<Radio value="YES">Yes</Radio>
						<Radio value="NO">No</Radio>
					</RadioGroup>

					<ZenSelect
						label="How did you hear about HelpingMinds? (Select an option or enter another provider)"
						formName="whereClientHearAboutUs"
						formRef={control}
						options={HowDoYouHearAboutUs.map<Option>((h) => ({ label: h, id: h }))}
						creatable
					/>

					<ZenSelect
						label="Source of Initial Contact"
						formName="initialContactSource"
						formRef={control}
						options={contactList.map<Option>((c) => ({ label: c.name, id: c.id }))}
						isLoading={initialContactSourceData.loading}
					/>

					<ZenSelect
						label="Is the Client Linked With Other Service Providers?"
						formName="serviceProvider"
						formRef={control}
						options={serviceList.map<Option>((s) => ({ id: s.id, label: s.name }))}
						isLoading={serviceProviderData.loading}
					/>

					{serviceProvider && serviceProvider[0] && serviceProvider[0].label === "Other" && (
						<ZenInput
							placeholder="Enter Service Provider"
							label={"Other"}
							nameRef="customServiceProvider"
							inputError={errors.customServiceProvider}
							formRef={control}
							required
						/>
					)}

					<ZenTextArea label="What Help is the Client Looking For?" nameRef="helpLookingFor" formRef={control} />
					{!isOrganisation &&
						<>
							<ZenSelect
								label="Are you currently caring for someone with a NDIS plan?"
								formName="currentlyCaringForNdisPlan"
								formRef={control}
								clearable={true}
								options={responseOptions}
							/>
							<ZenSelect
								label="Has the person you are caring for applied for a NDIS plan and been declined?"
								formName="hasAppliedAndDeclinedNdisPlan"
								formRef={control}
								clearable={true}
								options={responseOptions}
							/>
						</>
					}

					<div className={group}>
						<LabelMedium marginBottom="8px">Discuss the following services with the client</LabelMedium>
					</div>

					<ServiceCheckTable
						explainedServiceIDs={explainedServiceIDs}
						interestedServiceIDs={interestedServiceIDs}
						setExplainedServiceIDs={setExplainedServiceIDs}
						setInterestedServiceIDs={setInterestedServiceIDs}
					/>
				</div>

				{/* err message */}
				{fileUpload.error && <ErrorNotification messageOrPayload={fileUpload.payload} />}
				{countryData.error && <ErrorNotification messageOrPayload={payload} />}
				{languageData.error && <ErrorNotification messageOrPayload={languageData.payload} />}
				{initialContactSourceData.error && <ErrorNotification messageOrPayload={initialContactSourceData.payload} />}
				{serviceProviderData.error && <ErrorNotification messageOrPayload={serviceProviderData.payload} />}
				{genderData.error && <ErrorNotification messageOrPayload={genderData.payload} />}
				{error && <ErrorNotification messageOrPayload={payload} />}
				<ErrorFieldTracker errorIDs={formState.submitCount > 0 && contactError ? Object.keys(errors).concat("clientContact") : Object.keys(errors)} />
				<CancelAndSaveButtons width="100%" cancelFn={back} isLoading={clientLoading || loading} />
			</form>
			<Prompt when={formState.isDirty} message={"You have unsaved changes, are you sure you want to leave?"} />
		</>
	)
}
