import { type } from "os"
import {
	EmploymentStatus,
	FormType,
	NDISFileType,
	NDISLineItemType,
	NDISLineItemUnits,
	NDISLineItemVariant,
	NDISPlanStatus,
	NDISPriceType,
	ReviewStatus,
	SessionClientType,
} from "./enums"

export interface ErrorObject {
	message: string
	error_code: string
}

export interface UserError {
	message: string
	field: string[]
}

export interface BasicLabel {
	id: string
	label: string
}

export interface BasicName {
	id: string
	name: string
	deletedAt?: string
}

export interface CallPurpose {
	id: string
	label: string
	acceptMultiTypes: boolean
	deletedAt?: string
}

export interface GraphData {
	label: string
	distance: number
}

export interface OfficeWithAddress {
	id: string
	name: string
	address: Address
	deletedAt?: string
}

export interface UserDetail extends User {
	role: Role
	avatarURL?: string
	position: BasicName
	department: BasicName
	hoursPerFortnight: string
	fte: number | string
	fortnightlyKpis: string
	offices?: UsersOffice[]
	supervisors?: User[]
	vehicleRegistrationNumber?: string
	verified: boolean
	emergencyContacts?: UserEmergencyContact[]
	assignedClients: AssignedClient[]
	createdAt: string
}

export interface User {
	id: string
	email: string
	status: EmploymentStatus
	avatarURL?: string
	firstName: string
	lastName: string
	mobileNumber: string
	dateOfBirth: string
	address?: Address
	position: BasicName
	department: BasicName
	deletedAt?: string
	offices?: UsersOffice[]
	timezoneID: "Australia/Perth" | "Australia/Darwin"
}

export interface UserWithRole extends User {
	role: Role
}

export interface UsersOffice {
	day: string
	office: BasicName
}

export interface UserOfficesInput {
	officeID: string
	day: string
}

export interface UserEmergencyContact {
	relationship: string
	emergencyContact: EmergencyContact
}

export interface Client {
	id: string
	firstName: string
	lastName: string
	preferredName?: string
	dateOfBirth?: string
	avatarURL?: string
	countryOfBirthID?: string
	countryOfBirth?: Country
	languageSpokenAtHomeID?: string
	languageSpokenAtHome?: Language
	requiresTranslator?: boolean
	residencyStatus?: string
	genderID?: string
	gender?: Gender
	ndisNumber?: string
	contractAreaID?: string
	culturallyAndLinguisticallyDiverse?: boolean
	lesbianGayBisexualAndTransgender?: boolean
	aboriginalOrTorresStraitIslander?: string
	howDidYouHearAboutUs?: string
	currentlyCaringForNdisPlan?: string
	hasAppliedAndDeclinedNdisPlan?: string
	receiveUpcomingEventInfo?: boolean
	clientIntakeType?: string
	deletedAt?: string
	ndisPlan?: NDISPlan
	createdByID: string

	// organisation fields
	isOrganisation: boolean
	dateOfFirstContact?: string
	pronounSection?: string
}

export interface NDISPlan {
	id: string
	clientID: string
	ndisNumber: string
	planStartDate: string
	planEndDate: string
	status: NDISPlanStatus
	shortTermGoals: string
	mediumOrLongTermGoals: string
	priceType: NDISPriceType
	note: string
	supportBudgetTransferBalance: number
	hasServicesOutOfRange: boolean
	buddySessionMinutes: number
}

export interface NDISPlanMigrate extends NDISPlan {
	migratedSessionsCount: number
}

export interface NDISPlanLinePart {
	id: string
	lineItem: NDISLineItem
	managedBy: NDISLineItemManager
	budget: string
	remainingBudget: string
	weekRemainingBudget: string
}

export interface NDISPlanLine {
	id: string
	inGapManagement: boolean
	gapManagementFrom?: string
	gapManagementTo?: string
	support: NDISPlanLinePart
	travel?: NDISPlanLinePart
	transport?: NDISPlanLinePart
	serviceStartDate: string
	serviceEndDate: string
	supportLineItemID: string
	supportManagedByID: string
	travelLineItemID: string
	travelManagedByID: string
	deletedAt?: string
}

// session allocated line items
export interface SessionAllocatedLineItem extends NDISPlanLine {
	durationMinutes?: number
	supportLineItems: NDISLineItem[]
}

export interface ValueCharge {
	id: string
	planID: string
	chargeID: string
	blobID?: string
	description: string
	amount: string
	chargeDate: string
	createdAt: string
	deletedAt?: string
}

export interface ValueChargeDetails extends ValueCharge {
	lineItem: NDISLineItem
}

export interface NDISPriceGuide {
	id: string
	version: string
	createdAt: string
	count: number
	deletedAt?: string
	blobID: string
}

export interface PriceGuideImportResponse {
	priceGuide?: NDISPriceGuide
	validationErrors: string[]
}

export interface NDISTransferSupportBudget {
	plan: NDISPlan
	fromPlanLine: NDISPlanLine
	toPlanLine: NDISPlanLine
}

export interface NDISLineItemManager {
	id: string
	label: string
	parentID?: string
	deletedAt?: string
}

export interface NDISLineItem {
	id: string
	itemNumber: string
	itemName: string
	itemType: NDISLineItemType
	description: string
	unit: NDISLineItemUnits
	priceNational?: string
	priceRemote?: string
	priceVeryRemote?: string
	hasTravel: boolean
	hasTransport: boolean
	activityTransportID: string
	providerTravelID: string
	isSupportCoordinatorCode: boolean
	rateVariant: NDISLineItemVariant
	deletedAt: string
	createdAt: string
	updatedAt: string
}

export interface NDISItemGroupBase {
	id: string
	standardLineID: string
	saturdayLineID?: string
	sundayLineID?: string
	holidayLineID?: string
	eveningLineID?: string
	deletedAt: string
	createdAt: string
	updatedAt: string
}

export interface NDISItemGroup extends NDISItemGroupBase {
	standardItemNumber: string
	standardItemName: string
	standardItemType: NDISLineItemType
}

export interface ItemGroupDetail extends NDISItemGroupBase {
	standardItem: NDISLineItem
	saturdayItem?: NDISLineItem
	sundayItem?: NDISLineItem
	holidayItem?: NDISLineItem
	eveningItem?: NDISLineItem
}

export interface NDISPlanFile {
	id: string
	plan_id: string
	blob: Blob
	fileType: NDISFileType
	createdAt: string
}

export interface ClientWithContact extends Client {
	currentContact?: Contact
}

export interface ClientDetail extends Client {
	hasApproximateDateOfBirth: boolean
	currentContact?: Contact
	previousContacts?: Contact[]
	leaveVoiceMessage: boolean
	leaveTextMessage: boolean
	agreedToReceiveEmails: boolean
	receiveUpcomingEvent: boolean
	createdAt: string
	serviceProvider?: BasicName
	customServiceProvider?: string
	initialContactSource?: BasicName
	emergencyContacts?: ClientEmergencyContact[]
	parentGuardians?: ClientParentGuardian[]
	assignedWorkers?: User[]
	clientHealthTreatmentTeams?: ClientHealthTreatmentTeam[]
	relatedService: ClientRelatedService
	helpLookingFor?: string
	contractArea?: BasicLabel
	pronounOption: string
}

export interface ClientHealthTreatmentTeam {
	id: string
	typeOfSupport?: string
	organisation?: string
	practice?: string
	name: string
	contactName: string
	contactNumber: string
	frequencyOfSupport: string
	consentToContact: boolean
	consentNotes?: string
	deletedAt: string
}

export interface ClientParentGuardian {
	id: string
	firstName: string
	lastName: string
	telephoneNumber?: string
	mobileNumber?: string
	relationship: string
	dateOfBirth: string
	countryOfBirth: Country
	languageSpokenAtHome: Language
	requiresTranslator: boolean
	culturallyAndLinguisticallyDiverse: boolean
	aboriginalOrTorresStraitIslander: string
	createdAt: string
	deletedAt?: string
}

export interface ClientRelatedService {
	explainedServiceList: string[]
	interestedServiceList: string[]
}

interface ClientIntake {
	id: string
	// worker/poster detail
	worker: UserDetail
	referrerName?: string
	referrerAgencyRelationship?: string
	referrerContactNumber?: string
	referrerEmail?: string
	// language
	countryOfBirth: Country
	languageSpokenAtHome: Language
	requiresTranslator: boolean
	residencyStatus: string
	// gender
	genderID: string
	genderLabel: string
	culturallyAndLinguisticallyDiverse: boolean
	lesbianGayBisexualAndTransgender: boolean
	aboriginalOrTorresStraitIslander: string
	// presence of risks concerns
	unstableHousing?: string
	// challenge for the carer
	learnCognitiveDifficulties?: string
	mentalHealthConcerns?: string
	physicalDifficulties?: string
	accessibilityRequirements?: string
	riskOfFalls?: string
	allergies?: string
	medicalHistory?: string
	palliativeSupportCare?: string
	// presence of risk concerns
	suicidalIdeationSelfHarm?: string
	violenceAbuse?: string
	aodIssue?: string
	otherResiding?: string
	// care recipient
	careRecipientName?: string
	careRecipientRelationship?: string
	careRecipientHealthConcerns?: string
	careRecipientReceivingFormalSupport?: string
	// home and family
	liveWithWho?: string
	liveWithCarer: boolean
	relationshipWithFamilyMember?: string
	// support
	informalSupport?: string
	formalSupport?: string
	// strengths
	protectiveFactors?: string
	// support needs
	supportNeedAndDesiredOutcome?: string
	supportAllocation?: string[]
	additionalComment?: string
	// marketing
	whereClientHearAboutUs?: string
	initialContactSource?: BasicName
	helpLookingFor?: string
}

export interface ClientNDISIntake extends ClientIntake {
	// presence of risk concerns
	financialHardship?: string
	// current carer concerns
	carerConcern?: string
	// home and family
	spendTimeWithFamily?: string
	// support
	workAndOrEducation?: string
	// support needs
	appointmentDetail?: string
}

export interface ClientLanguage {
	countryOfBirth: Country
	languageSpokenAtHome: Language
}

export interface Contact {
	email?: string
	mobileNumber?: string
	residentialAddress?: Address
	postalAddress?: Address
	telephoneNumber?: string
	deletedAt?: string
	createdAt: string
}

export interface AssignedClient {
	id: string
	firstName: string
	lastName: string
	avatarURL?: string
	workerName?: string
}

export interface RemovedAssignedWorker {
	worker: User
	archivedByUser: User
	reason: string
	deletedAt: string
}

export interface RemovedAssignedClient {
	client: Client
	archivedByUser: User
	reason: string
	deletedAt: string
}

// emergency contact
export interface EmergencyContact {
	id: string
	firstName: string
	lastName: string
	telephoneNumber?: string
	mobileNumber?: string
	createdAt?: string
}

// supplementary
export interface Supplementary {
	id: string
	clientHelpDescription: string
	initialContactSourceID: string
	consentCollectUseInfo: boolean
	consentToContact: boolean
	interestedServices: InterestedExplainedService[]
	explainedServices: InterestedExplainedService[]
	whereDidTheClientHearAboutUs: string
}

// interested explained
export interface InterestedExplainedService {
	relatedServiceID: string
	interested: boolean
	explained: boolean
}

// country
export interface Country {
	id: string
	countryName: string
}

// referrer
export interface Referrer {
	id: string
	referrerName: string
	subReferrers?: Referrer[]
	deletedAt?: string
}

// language
export interface Language {
	id: string
	label: string
	deletedAt?: string
}

// gender
export interface Gender {
	id: string
	label: string
	deletedAt?: string
}

export interface ResponseOptions {
	id: string
	label: string
}

// related service
export interface RelatedService {
	id: string
	name: string
	deletedAt: string
}

// service provider
export interface ServiceProvider {
	id: string
	name: string
	deletedAt: string
}

export interface ClientEmergencyContact {
	emergencyContact: EmergencyContact
	relationshipToClient: string
	relationshipToConsumer: string
	deletedAt?: string
}

export interface Country {
	id: string
	countryName: string
}

export interface ErrorMap {
	[key: string]: string
}

export interface Role {
	id: string
	name: RoleName
	permissions: RolePermission[]
}

export interface Car {
	id: string
	label: string
	location: string
	make: string
	model: string
	regoNumber: string
	deletedAt?: string
}

export interface Address {
	id?: string
	placeID: string
	displayName: string
	fullAddress: string
	addressLine1: string
	addressLine2: string
	suburb: string
	postalCode: string
	state: string
}

export interface Travel {
	id: string
	identifier: number
	session?: Session
	sessionID?: string
	client?: ClientWithContact
	anotherClient?: ClientWithContact
	worker: UserDetail
	timezoneID: string
	startTime: string
	endTime: string
	fromAddress: Address
	toAddress: Address
	maximumDistancePayable?: number
	actualDistanceMetres: number
	actualDurationSeconds: number
	calculatedDistanceMetres: number
	calculatedDurationSeconds: number
	mileageClaimed: boolean
	timeClaimed: boolean
	isLocked: boolean
	withClient: boolean
	deletedAt?: string
	car?: Car
	travelType: TravelType
	multiplier: string
	isWeekend: boolean
	travelExpenses?: TravelExpense[]
	odometerBeforeKM?: number
	odometerAfterKM?: number
	timesheet?: Timesheet
	mileageClaim?: MileageClaim
	workerID: string
	fundingSource?: BasicLabel
	distanceIsManuallySet: boolean
	endTimeIsManuallySet: boolean
}

export interface TravelExpense {
	id: string
	expenseType: string
	amount: string
	fileName?: string
	fileURL?: string
	blobID?: string
}

export interface MileageClaim {
	id: string
	user: User
	readyAt?: string
	reviewStatus: ReviewStatus
	reviewedBy?: User
	reviewedAt?: string
	travels: Travel[]
	periodStart: string
	periodEnd: string
	createdAt: string
	completedBy?: User
	completedAt?: string
	checkedBy?: User
	checkedAt?: string
	declinedReason?: string
	mileageNotes?: string
}

export interface Timesheet {
	id: string
	user: User
	userID: string
	readyAt: string
	reviewStatus: ReviewStatus
	reviewedBy?: User
	reviewedAt?: string
	declinedReason?: string
	travels: Travel[]
	sessions: Session[]
	completedAt?: string
	completedBy?: User
	checkedAt?: string
	checkedBy?: User
	periodStart: string
	periodEnd: string
	createdAt: string
	multiplier: string
	totalMinutes: number
	timesheetNotes?: string
}

export interface MileageClaimResult {
	mileageClaims?: MileageClaim[]
	validationErrors?: string[]
}

export interface TimesheetResult {
	timesheets?: Timesheet[]
	validationErrors?: string[]
}

export interface TravelType {
	id: string
	label: string
	code: string
	distancePayable: boolean
}

export interface CallLog {
	id: string
	identifier: number
	type: string
	worker: UserDetail
	startTime: string
	endTime: string
	callFrom: string
	callerName?: string
	client?: Client
	phoneNumber: string
	callPurpose: BasicLabel
	callPurposeTypes?: BasicLabel[]
	transferredToDepartment?: BasicName
	transferredToWorker?: UserDetail
	notes?: Note[]
	deletedAt?: string
	updatedAt: string
	createdAt: string
}

export interface ClientRelatedNote {
	id: string
	sourceID: string
	sourceName: string
	noteTypeLabel: string
	revisionNotes: Note[]
	poster: UserDetail
}

export interface SessionNoteContent {
	"Observations of clients overall Health/Wellbeing": string
	"Actions undertaken during session": string
	"Plan for next session": string
	"Other comments/notes": string
}

export interface Note {
	id: string
	type: NoteType
	audioURL: string
	content: string
	contentJSON: SessionNoteContent
	revisions: Note[]
	poster: UserDetail
	sessionNoteNdisDetailID?: string
	deletedAt: string
	createdAt: string
}

export interface NoteType {
	id: string
	name: string
	deletedAt?: string
}

// NoteTypeItem is a noteType wrapper use for structure such as client note and session note
export interface NoteTypeItem {
	id: string
	deletedAt?: string
	noteType: NoteType
}

export interface Alert {
	id: string
	poster?: UserDetail
	client: Client
	content: string
	alertType: string
	revisions: Alert[]
	alertSubtype: string
	createdAt: string
	deletedAt: string
}

export interface AlertType {
	id: string
	name: string
	deletedAt?: string
}

export interface Session extends PostSession, SessionNDISInfo {
	id: string
	identifier: number
	type: string
	worker: UserDetail
	workers: UserDetail[]
	additionalWorkers?: AddedWorker[]
	clients?: Client[]
	groupUnidentifiedAttendee?: boolean
	groupAtslNumber?: number
	groupCaldNumber?: number
	groupYearGroup?: string
	groupAttendees: Client[]
	groupProgramDelivered?: DeliveredProgram
	timezoneID: string
	startTime: string
	endTime: string
	meetingType: string
	sessionLocation: string
	attendeeNumber: number
	sessionFundingSources: SessionFundingSource[]
	preSessionNote?: SessionsNote
	deletedAt?: string
	updatedAt: string
	createdAt: string
	multiplier: string
	isWeekend: boolean
	isNonBillable: boolean
	timeClaimed: boolean
	isLocked: boolean
	office?: OfficeWithAddress
	timesheets?: Timesheet[]
	canTransportClients: Client[]
	canTravelClients: Client[]
	workerID: string
	clientType?: SessionClientType
}

export interface SessionFundingSource {
	fundingSource: FundingSource
	contractArea?: ContractArea
	supportType?: SupportType
	subSupportType?: SubSupportType
	subSubSupportType?: SubSupportType
}

export interface FundingSource extends BasicLabel {
	deletedAt?: string
}

export interface SupportType extends BasicLabel {
	deletedAt?: string
	fundingSourceID: string
}

export interface ContractArea extends BasicLabel {
	deletedAt?: string
	fundingSourceID: string
}

export interface SubSupportType extends BasicLabel {
	deletedAt?: string
	supportTypeID: string
}

export interface SessionNDISPlanLine {
	ndisPlanLineID: string
	durationMinutes: number
}

export interface DeliveredProgram {
	id: string
	label: string
	deletedAt?: string
}

// extra field for post session
export interface PostSession {
	attendanceStatus?: string
	actualStartTime?: string
	actualEndTime?: string
	notifyTeamLeader?: UserDetail
	notificationMadeTo?: UserDetail
	notificationWasSentBy?: string
	actionsTakenToLocateClient?: string
	reasonSessionEndedEarly?: string
	actionTaken?: string
	timeOfNotification?: string
	timeOfClientContact?: string
	timeSessionEndedEarly?: string
}

export interface SessionNDISInfo {
	dateForCompletingSessionAdmin?: string
	startTimeForSessionAdmin?: string
	minutesRequiredForAdminTasks?: number
	timeToBeAdded?: boolean
	timeIncludedWithSession?: boolean
	buddySessionWithMentor?: boolean
	buddySessionMentor?: UserDetail
}

export interface ContractTimeReport {
	name: string
	workers: {
		firstName: string
		lastName: string
		duration: number
		supportTypes: string[]
	}[]
}

export interface AddedWorker {
	worker: UserDetail
	isBuddyMentor: boolean
	claimHour: boolean
}

export interface SessionsNote {
	sessionID: string
	note: Note
	client: Client
}

export interface AuditLog {
	id: string
	workers: UserDetail[]
	clients?: Client[]
	objectType: ObjectType
	action: string
	beforeMeta: any
	afterMeta?: any
	createdAt: string
}

export interface Form<T> {
	id: string
	name: string
	type: FormType
	clientID: string
	content: T
	requiresAction: boolean
	nextReviewDate?: string
	reviewRecords?: ReviewRecord[]
	client: Client
	createdAt: string
	createdByName: string
	updatedAt: string
	updatedByName: string
	deletedAt?: string
	signatureURL?: string
}

export interface ReviewRecord {
	worker: UserDetail
	date: string
}

export interface ClientFile {
	id: string
}

export interface ClientHour {
	client: Client
	hours: number
}

export interface CheckAvailability {
	available: boolean
	message: string
	startTime?: string
	endTime?: string
	session?: Session
	travel?: Travel
}

export interface NDISInvoice {
	planManager: string
	ndisNumber: string
	supportsDeliveredFrom: string
	supportsDeliveredTo: string
	supportNumber: string
	claimReference: string
	area: string
	quantity: string
	hours: string
	decimal: number
	unitPrice: number
	gstCode: string
	total: number
}

export enum RoleName {
	SuperAdmin = "SUPER_ADMIN",
	Admin = "ADMIN",
	General = "GENERAL",
	Supervisor = "SUPERVISOR",
	Superuser = "SUPERUSER",
	Intake = "INTAKE",
}

export interface Timezone {
	id: string
	label: string
	offset: string
	offsetMinutes: number
}

export const TZString: Timezone[] = [
	{ label: "Perth - GMT+08:00", id: "Australia/Perth", offset: "+08:00", offsetMinutes: -480 },
	{ label: "Darwin - GMT+09:30", id: "Australia/Darwin", offset: "+09:30", offsetMinutes: -570 },
]

// Permissions including List, Create, Read, Update, Archive
export enum RolePermission {
	// User
	UserRead = "UserRead",
	UserCreate = "UserCreate",
	UserUpdate = "UserUpdate",
	UserInvalidateTokens = "UserInvalidateTokens",
	// Note
	NoteArchive = "NoteArchive",
	// Travel
	TravelRead = "TravelRead",
	TravelUpdate = "TravelUpdate",
	// Report
	ReportRead = "ReportRead",
	ReportCreate = "ReportCreate",
	ReportUpdate = "ReportUpdate",
	// Client
	ClientUpdate = "ClientUpdate",
	ClientRead = "ClientRead",
	// Session
	SessionRead = "SessionRead",
	SessionUpdate = "SessionUpdate",
	SessionNoteUpdate = "SessionNoteUpdate",
	// Audit Log
	AuditLogRead = "AuditLogRead",
	// Other
	ImpersonateUser = "ImpersonateUser",
	// Executive
	Executive = "Executive",
	ExecutiveCorporate = "ExecutiveCorporate",
	// NDIS
	NDISPlanCreate = "NDISPlanCreate",
	NDISPlanRead = "NDISPlanRead",
	NDISPlanUpdate = "NDISPlanUpdate",
	NDISAdmin = "NDISAdmin",
	TriggerRecalculate = "TriggerRecalculate",
	NDISPlanTransferSupportBudget = "NDISPlanTransferSupportBudget",
	NDISPlanMigrate = "NDISPlanMigrate",
	NDISBalanceAdjustment = "NDISBalanceAdjustment",
	LockDateManage = "LockDateManage",
	// Call Log
	CallLogRead = "CallLogRead",
	CallLogUpdate = "CallLogUpdate",
	// Payroll
	PayrollComplete = "PayrollComplete",
	// support worker
	SupportWorker = "SupportWorker",
	// support coordinator
	SupportCoordinator = "SupportCoordinator",

	// Team
	TeamCreate = "TeamCreate",

	// Manage completed report
	CompletedReportManage = "CompletedReportManage",

	SelectableOptionManage = "SelectableOptionManage",
}

export enum ObjectType {
	ObjectTypeSelf = "Self",
	ObjectTypeUser = "User",
	ObjectTypeWorker = "Worker",
	ObjectTypeAdmin = "Admin",
	ObjectTypeClient = "Client",
}

export interface Blob {
	id: string
	fileName: string
	fileSizeBytes: number
	fileURL: string
}

export interface LabelLink {
	label: string
	link: string
}

export const NDISFundingSource = "NDIS or NDIS Potential Client"

export enum SESSION_NOTE_TYPE {
	PreSession = "Pre-Session",
	General = "General",
	ClinicalSupport = "Clinical / Support",
	Advocacy = "Advocacy",
	CallContact = "Call / Contact",
	IntactReferral = "Intake / Referral",
	Session = "Session",
	InductionHelpRequest = "Induction Help Request",
}

export enum SESSION_NDIS_NOTE_TYPE {
	General = "General",
	FaceToFace = "Face to Face",
	SMS = "SMS",
	Email = "Email",
	Telephone = "Telephone",
	Video = "Video",
}

export enum ServiceList {
	Advocacy = "Advocacy",
	EducationalWorkshops = "Educational Workshops",
	SupportGroups = "Support Groups",
	RecoveringOurFamiliesROF = "Recovering Our Families (ROF)",
	YouthServicesCounselling = "Youth Services (counselling)",
	YouthServicesSchoolHolidayProgramme = "Youth Services (School Holiday Programme)",
	PeerSupport = "Peer Support",
	Respite = "Respite",
	ConsentForHMToCollectAndUseInfo = "Consent for HM to collect and use info",
	ConsentForHMToContactClientForFutureSurvey = "Consent for HM to contact client for future surveys/research/evaluation?",
	FamilySupportCounselling = "Family Support Counselling",
	VideoConsellingBeingThere = "Video Counselling (Being There)",
}

export const TimeFormat = "YYYY-MM-DDTHH:mm:ss"

export enum SessionType {
	Single = "SINGLE",
	Group = "GROUP",
}

export enum RangeOption {
	thisWeek = "This week",
	thisMonth = "This month",
}

export enum SortOrder {
	Ascending = "Ascending",
	Descending = "Descending",
}

// options for atsi (Aboriginal or Torres Strait Islander)
export enum atsiOption {
	AboriginalButNotTorresStraitIslander = "Aboriginal, but not Torres Strait Islander",
	TorresStraitIslanderButNotAboriginal = "Torres Strait Islander, but not Aboriginal",
	AboriginalAndTorresStraitIslander = "Aboriginal AND Torres Strait Islander",
	NeitherAboriginalNorTorresStraitIslander = "Neither Aboriginal NOR Torres Strait Islander",
	PreferNotToSay = "Prefer not to say",
}

export enum residencyStatus {
	AustraliaCitizen = "Australia Citizen",
	PermanentResidency = "Permanent Residency",
	Visa = "Visa",
}

export const HowDoYouHearAboutUs = [
	"Internet Search",
	"Social Media",
	"Eventbrite",
	"Radio",
	"Medical Setting i.e. hospital",
	"Another organisation",
	"Family or friend",
	"Pamphlet/Poster",
	"Newspaper",
	"Open day / event / stall",
	"Previous client",
	"Google Ads",
	"Facebook Ads",
	"Facebook post/group",
	"Twitter post",
	"Instagram post/story",
	"TV",

]

export const ZenDateFormat = "DD/MM/YYYY"

export const ZenTimeFormat = "h:mm a"

export const ZenDateTimeFormat = `${ZenDateFormat} ${ZenTimeFormat}`

export const SearchTextMinLength = 3

export interface WorkerOrClient {
	id: string
	firstName: string
	lastName: string
}

export interface BlackListScope {
	name?: string
	type?: string
	description?: string
	Scope?: BlackListScope[]
}

export interface BlacklistUser {
	id: string
	worker?: WorkerOrClient
	client?: WorkerOrClient
	type?: string
	name?: string
	description?: string
	scope?: BlackListScope[]
	createdAt?: string
	deletedAt?: string
}
  