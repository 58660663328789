import * as React from "react";
import { useStyletron } from "baseui";
import { LabelLarge } from "baseui/typography";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { CancelAndSaveButtons } from "../../../cancelSaveButtons";
import { ZenCheckbox } from "../../../zenComponents/zenCheckboxList";
import { ZenInput, ZenTextArea } from "../../../zenComponents/zenInput";
import { IntakeScrollDiv } from "../intakeComponent";
import { IntakeAdultProps, intakeURLParser } from "./intakeAdultBaseForm";
import { ErrorFieldTracker } from "../../../forms/errorFieldTracker";
import { ZenButton } from "../../../zenComponents/zenButtons"

export const IntakeAdultCareContext = (props: IntakeAdultProps) => {
	const { clientID, formID, isReadOnly, validationTriggered } = props;
	const [css] = useStyletron();
	const history = useHistory();

	const { watch, trigger, control, errors, getValues } = useFormContext();
	const receivingFormalSupports = watch("receivingFormalSupports");

	const validation = React.useCallback(async () => {
		const currentState = getValues();

		const checkList: string[] = [];
		if (currentState.receivingFormalSupports) checkList.push("formalSupportDetail");

		const isValid = await trigger(checkList);
		return isValid;
	}, [trigger, getValues]);
	React.useEffect(() => {
		if (validationTriggered) validation();
	}, [validation, validationTriggered]);

	const onSubmit = async () => {
		const checkList: string[] = [];
		if (receivingFormalSupports) checkList.push("formalSupportDetail");

		const isValid = await validation();
		if (!isValid) return;

		// route to next page
		history.push(intakeURLParser(`/portal/clients/${clientID}/intake/adult/carerStar`, formID));
	};

	const container = css({
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		backgroundColor: "white",
		flexDirection: "row",
	});
	const formStyle = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		paddingBottom: "25px",
		paddingTop: "25px",
	});
	const body = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		minHeight: 0,
	});
	const subForm = css({
		padding: "20px",
	});

	const [careRecipients, setCareRecipients] = React.useState([{ id: 1, name: "", relationship: "", mentalHealthDiagnosisConcern: "" }]);

	const addCareRecipient = () => {
		setCareRecipients([...careRecipients, { id: careRecipients.length + 1, name: "", relationship: "", mentalHealthDiagnosisConcern: "" }]);
	};

	const deleteCareRecipient = (id: number) => {
		// Prevent deletion if it’s the first care recipient
		if (id === 1) return;
		setCareRecipients(careRecipients.filter(recipient => recipient.id !== id));
	};


	return (
		<div className={container}>
			<form autoComplete="off" className={formStyle}>
				<div className={body}>
					<LabelLarge>Client Context</LabelLarge>
					<IntakeScrollDiv>
						<div className={subForm}>
							{careRecipients.map((recipient, index) => (
								<div key={recipient.id} className={css({ marginBottom: "20px" })}>
									<ZenInput
										disabled={isReadOnly}
										label={`Care Recipient ${index + 1} Name`}
										nameRef={`careRecipients[${index}].name`}
										formRef={control}
									/>
									<ZenInput
										disabled={isReadOnly}
										label="Relationship"
										nameRef={`careRecipients[${index}].relationship`}
										formRef={control}
										placeholder="Enter relationship"
									/>
									<ZenTextArea
										disabled={isReadOnly}
										label="Mental Health Diagnosis/Concern"
										nameRef={`careRecipients[${index}].mentalHealthDiagnosisConcern`}
										formRef={control}
										placeholder="Enter details"
										initialHeight={180}
										getValues={getValues}
									/>

									<ZenCheckbox
										disabled={isReadOnly}
										label="Is the consumer receiving formal support?"
										formName="receivingFormalSupports"
										formRef={control}
										overrides={{ Root: { style: { marginTop: "20px" } } }}
									/>
									{receivingFormalSupports && (
										<ZenTextArea
											disabled={isReadOnly}
											label="Please list relevant information"
											nameRef="formalSupportDetail"
											formRef={control}
											formRules={{ required: "Detail is required" }}
											placeholder="Enter details about receiving formal support"
											inputError={errors.formalSupportDetail}
											initialHeight={180}
											getValues={getValues}
										/>
									)}
									{index > 0 && (
										<ZenButton type="button" onClick={() => deleteCareRecipient(recipient.id)}>
											Delete Care Recipient
										</ZenButton>
									)}
								</div>
							))}

							<ZenButton type="button" onClick={addCareRecipient}>
								Add another Care Recipient
							</ZenButton>
						</div>

						{/* New Section: Home and Family */}
						<LabelLarge marginTop="10px">Home and Family</LabelLarge>
						<div className={subForm}>
							<ZenTextArea
								disabled={isReadOnly}
								label="Who is living in the home"
								nameRef="homeFamilyDetails"
								formRef={control}
								placeholder="Enter details of people living in the home"
								initialHeight={180}
								getValues={getValues}
							/>
						</div>

						<LabelLarge marginTop="10px">Current Carer Concerns</LabelLarge>
						<div className={subForm}>
							<ZenTextArea
								disabled={isReadOnly}
								label="Reasons for referral, impact of the issues, duration of issues etc."
								nameRef="currentCarerConcerns"
								formRef={control}
								placeholder="Enter details"
								initialHeight={180}
								getValues={getValues}
							/>
						</div>
					</IntakeScrollDiv>

				</div>
				<ErrorFieldTracker errorIDs={Object.keys(errors)} />
				{!isReadOnly && <CancelAndSaveButtons cancelLabel="Back" cancelFn={() => history.goBack()} saveLabel="Next" saveFn={onSubmit} />}
			</form>
		</div>
	);
};
