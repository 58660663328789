import * as React from "react"
import { useStyletron } from "baseui"
import { Option } from "baseui/select"
import { StyledSpinnerNext } from "baseui/spinner"
import moment from "moment-timezone"
import { useMutation, useParameterizedQuery, useQuery } from "react-fetching-library"
import { FormProvider, useForm } from "react-hook-form"
import { Prompt, Route, RouteComponentProps, Switch, useHistory } from "react-router-dom"

import { AuthContainer } from "../../../../controllers/auth"
import { PortalContainer } from "../../../../controllers/portal"
import { fetching } from "../../../../fetching"
import { phoneNumberRegex } from "../../../../helpers/utils"
import { IntakeType } from "../../../../types/enums"
import { TempIntakeYouthFormData, YouthParentGuardianDetailInput } from "../../../../types/inputTypes"
import { Country, Form, LabelLink, Language } from "../../../../types/types"
import { ZenCard } from "../../../common"
import { ErrorNotification } from "../../../errorBox"
import { useZenToast } from "../../../zenComponents/useZenToast"
import { ZenVerticalProgress } from "../../../zenComponents/zenProgressBar"
import { intakeURLParser } from "../adult/intakeAdultBaseForm"
import { ActionPlan } from "../commonForms/actionPlan"
import { ChallengesForClient } from "../commonForms/challengesForClient"
import { Detail } from "../commonForms/detail"
import { PresenceOfRiskConcerns } from "../commonForms/presenceOfRiskConcerns"
import { Supports } from "../commonForms/supports"
import { validateActionPlan, validateChallengeForClient, validatePresenceOfRiskConcerns, validateReferrerDetail } from "../validation"
import { IntakeYouthMentalHealthContext } from "./intakeYouthMentalHealthContext"
import { IntakeYouthParentGuardianDetail } from "./intakeYouthParentGuardianDetail"
import { IntakeYouthRelationship } from "./intakeYouthRelationship"
import { IntakeYouthWorkAndSchool } from "./intakeYouthWorkAndSchool"
import { routes } from "routes"

const list: LabelLink[] = [
	{ label: "Referrer Details", link: "" },
	{ label: "Parent/Guardian Details", link: "parentGuardianDetails" },
	{ label: "Challenges for the Client", link: "challengesForTheClient" },
	{ label: "Presence of Risk Concerns", link: "presenceOfRiskConcerns" },
	{ label: "Mental Health Context", link: "mentalHealthContext" },
	{ label: "Relationship", link: "relationship" },
	{ label: "Work and School", link: "workAndSchool" },
	{ label: "Supports", link: "supports" },
	{ label: "Action Plan", link: "actionPlan" },
]

export interface IntakeYouthProps {
	clientID: string
	formID: string | null
	isReadOnly: boolean
	validationTriggered: boolean
}

export const BaseForm = (props: RouteComponentProps<{ id: string }>) => {
	const clientID = props.match.params.id
	const { fetchClient, client, clientLoading } = PortalContainer.useContainer()
	const [css] = useStyletron()
	const { showToast } = useZenToast()
	const history = useHistory()
	const [firstLoad, setFirstLoad] = React.useState<boolean>(true)
	const [step, setStep] = React.useState<number>(0)

	const searchArgs = new URLSearchParams(history.location.search)
	const formID = searchArgs.get("formID")
	const [formIsCompleted, setFormIsCompleted] = React.useState<boolean>(false)
	const { currentUser } = AuthContainer.useContainer()

	const formMethods = useForm()

	// form dirty state
	const [formDirty, setFormDirty] = React.useState(false)

	React.useEffect(() => {
		if (formMethods.formState.isDirty && !formDirty) setFormDirty(true)
	}, [formMethods.formState.isDirty, formDirty])

	// mutation function
	const clientIntakeCreate = useMutation<Client>(fetching.mutation.clientIntakeCreate)

	const { query, payload: formData, loading: formLoading } = useParameterizedQuery<Form<TempIntakeYouthFormData>>(fetching.query.getForm)
	const refetchForm = React.useCallback(() => {
		if (!formID) return
		query(formID)
	}, [formID, query])
	React.useEffect(() => {
		refetchForm()
	}, [refetchForm])

	// set up options
	const [countryOptions, setCountryOptions] = React.useState<Option[]>([])
	const [languageOptions, setLanguageOptions] = React.useState<Option[]>([])
	const countryData = useQuery<Country[]>(fetching.query.getCountryAll())
	const languageData = useQuery<Language[]>(fetching.query.getLanguageAll())
	React.useEffect(() => {
		if (countryData.loading || !countryData.payload) return
		setCountryOptions(countryData.payload.map<Option>((c) => ({ label: c.countryName, id: c.id })))
	}, [countryData.payload, countryData.loading])
	React.useEffect(() => {
		if (languageData.loading || !languageData.payload) return
		setLanguageOptions(languageData.payload.map<Option>((l) => ({ label: l.label, id: l.id })))
	}, [languageData.payload, languageData.loading])

	// Prepare form data for Parent and Guardian
	const [placeholder, setPlaceholder] = React.useState<number[]>([0]) // use for tracking the index of each emergency form
	const [count, setCount] = React.useState<number>(0) // track number of additional parent and guardian
	const [contactErrors, setContactErrors] = React.useState<boolean[]>([false]) // track whether there is at least one contact form

	const prefillData = React.useCallback(() => {
		if (formLoading || !formData) return
		const currentData: TempIntakeYouthFormData = formData.content
		setFormIsCompleted(!formData.requiresAction)

		// set fields - referrer data
		formMethods.setValue("referrerID", currentData.referrerID)
		formMethods.setValue("subReferrerID", currentData.subReferrerID)
		formMethods.setValue("referrerName", currentData.referrerName)
		formMethods.setValue("referrerAgencyRelationship", currentData.referrerAgencyRelationship)
		formMethods.setValue("referrerContactNumber", currentData.referrerContactNumber)
		formMethods.setValue("referrerEmail", currentData.referrerEmail)
		formMethods.setValue("confidentiallyDiscussed", !!currentData.confidentiallyDiscussed)
		formMethods.setValue("SummaryOfWhatToAcceptDuringTheIntakeProcessProvided", !!currentData.SummaryOfWhatToAcceptDuringTheIntakeProcessProvided)
		formMethods.setValue("VerbalConsentProvidedByClient", !!currentData.VerbalConsentProvidedByClient)
		// set default worker
		if (currentData.workerID) {
			formMethods.setValue("workerID", currentData.workerID)
		}

		// set fields - Parent and Guardian detail
		if (currentData.parentGuardianDetails) {
			const details = currentData.parentGuardianDetails

			setPlaceholder(details.length > 0 ? details.map<number>((e, i) => i) : [0])
			setCount(details.length > 1 ? details.length - 1 : 0)
			setContactErrors(details.length > 0 ? details.map(() => false) : [false])

			details.forEach((p: YouthParentGuardianDetailInput, i: number) => {
				formMethods.setValue(`parentGuardianFirstName${i}`, p.firstName)
				formMethods.setValue(`parentGuardianLastName${i}`, p.lastName)
				formMethods.setValue(`parentGuardianRelationship${i}`, p.relationship)
				formMethods.setValue(`parentGuardianTelephoneNumber${i}`, p.telephoneNumber)
				formMethods.setValue(`parentGuardianMobileNumber${i}`, p.mobileNumber)
				formMethods.setValue(`parentGuardianCountryOfBirthID${i}`, p.countryOfBirthID)
				formMethods.setValue(`parentGuardianLanguageSpokenAtHomeID${i}`, p.languageSpokenAtHomeID)
				formMethods.setValue(`parentGuardianRequiredTranslator${i}`, p.requiresTranslator)
				formMethods.setValue(`parentGuardianCulturallyAndLinguisticallyDiverse${i}`, p.culturallyAndLinguisticallyDiverse)
				formMethods.setValue(`parentGuardianAboriginalOrTorresStraitIslander${i}`, p.aboriginalOrTorresStraitIslander)
				if (p.dateOfBirth) {
					formMethods.setValue(`parentGuardianDateOfBirth${i}`, moment(p.dateOfBirth).toDate())
				}
			})
		}

		// set fields - Presence of risk concerns
		formMethods.setValue("suicidalIdeationSelfHarm", currentData.suicidalIdeationSelfHarm)
		formMethods.setValue("financialHardship", currentData.financialHardship)
		formMethods.setValue("unstableHousing", currentData.unstableHousing)
		formMethods.setValue("violenceAbuse", currentData.violenceAbuse)
		formMethods.setValue("aodIssue", currentData.aodIssue)
		formMethods.setValue("otherResiding", currentData.otherResiding)
		formMethods.setValue("withinLastThreeMonth", currentData.withinLastThreeMonth)
		formMethods.setValue("hasSuicidalIdeationSelfHarm", currentData.hasSuicidalIdeationSelfHarm)
		formMethods.setValue("hasFinancialHardship", currentData.hasFinancialHardship)
		formMethods.setValue("hasUnstableHousing", currentData.hasUnstableHousing)
		formMethods.setValue("hasViolenceAbuse", currentData.hasViolenceAbuse)
		formMethods.setValue("hasAODIssue", currentData.hasAODIssue)
		formMethods.setValue("fullCustodySoleGuardian", currentData.fullCustodySoleGuardian)
		formMethods.setValue("hasSharedCustodySecondaryParent", currentData.hasSharedCustodySecondaryParent)
		formMethods.setValue("sharedParentSecondaryGuardianFirstName", currentData.sharedParentSecondaryGuardianFirstName)
		formMethods.setValue("sharedParentSecondaryGuardianLastName", currentData.sharedParentSecondaryGuardianLastName)
		formMethods.setValue("sharedParentSecondaryGuardianRelationship", currentData.sharedParentSecondaryGuardianRelationship)
		formMethods.setValue("sharedParentSecondaryGuardianTelephoneNumber", currentData.sharedParentSecondaryGuardianTelephoneNumber)
		formMethods.setValue("sharedParentSecondaryGuardianMobileNumber", currentData.sharedParentSecondaryGuardianMobileNumber)
		formMethods.setValue("sharedParentSecondaryGuardianDateOfBirth", currentData.sharedParentSecondaryGuardianDateOfBirth)
		if (currentData.sharedParentSecondaryGuardianDateOfBirth) {
			formMethods.setValue("sharedParentSecondaryGuardianDateOfBirth", moment(currentData.sharedParentSecondaryGuardianDateOfBirth).toDate())
		}
		formMethods.setValue("sharedParentSecondaryGuardianCountryOfBirthID", currentData.sharedParentSecondaryGuardianCountryOfBirthID)
		formMethods.setValue("sharedParentSecondaryGuardianLanguageSpokenAtHomeID", currentData.sharedParentSecondaryGuardianLanguageSpokenAtHomeID)
		formMethods.setValue("sharedParentSecondaryGuardianRequiresTranslator", currentData.sharedParentSecondaryGuardianRequiresTranslator)
		formMethods.setValue(
			"sharedParentSecondaryGuardianCulturallyAndLinguisticallyDiverse",
			currentData.sharedParentSecondaryGuardianCulturallyAndLinguisticallyDiverse,
		)
		formMethods.setValue(
			"sharedParentSecondaryGuardianAboriginalOrTorresStraitIslander",
			currentData.sharedParentSecondaryGuardianAboriginalOrTorresStraitIslander,
		)
		formMethods.setValue("dualParentFamilySingleLivingParent", currentData.dualParentFamilySingleLivingParent)
		formMethods.setValue("hasSharedParentalResponsibilitySecondaryParent", currentData.hasSharedParentalResponsibilitySecondaryParent)
		formMethods.setValue(
			"hasSharedParentalResponsibilitySecondaryParentNotAwareOfService",
			currentData.hasSharedParentalResponsibilitySecondaryParentNotAwareOfService,
		)
		formMethods.setValue("hasSingleParentalResponsibilityProceedWithoutOtherConsent", currentData.hasSingleParentalResponsibilityProceedWithoutOtherConsent)
		formMethods.setValue("hasOtherFamilyArrangement", currentData.hasOtherFamilyArrangement)
		formMethods.setValue("singleParentAdditionalComment", currentData.singleParentAdditionalComment)
		formMethods.setValue("referHelpingMindsCaseReview", currentData.referHelpingMindsCaseReview)

		// set fields - home and family
		formMethods.setValue("whoLiveWithYou", currentData.whoLiveWithYou)
		formMethods.setValue("relationshipWithOtherInFamily", currentData.relationshipWithOtherInFamily)
		formMethods.setValue("generalBehaviourAtHome", currentData.generalBehaviourAtHome)
		formMethods.setValue("getAlongWithOthers", currentData.getAlongWithOthers)
		formMethods.setValue("activitiesGroupAfterSchool", currentData.activitiesGroupAfterSchool)
		formMethods.setValue("attendingSchoolStudyWork", currentData.attendingSchoolStudyWork)

		// set fields - Mental health context
		formMethods.setValue("name", currentData.name)
		formMethods.setValue("relationship", currentData.relationship)
		formMethods.setValue("mentalHealthDiagnosisConcern", currentData.mentalHealthDiagnosisConcern)
		formMethods.setValue("formalSupportDetail", currentData.formalSupportDetail)
		formMethods.setValue("youngPersonMentalHealth", currentData.youngPersonMentalHealth)
		formMethods.setValue("difficultiesToCopeWith", currentData.difficultiesToCopeWith)
		formMethods.setValue("behavioursOfConcern", currentData.behavioursOfConcern)
		formMethods.setValue("parentThought", currentData.parentThought)
		formMethods.setValue("receivingFormalSupports", currentData.receivingFormalSupports)
		formMethods.setValue("hasDiagnoses", currentData.hasDiagnoses)
		formMethods.setValue("hasMentalHealthConcernMember", currentData.hasMentalHealthConcernMember)

		// set fields - challenge for client
		formMethods.setValue("learningCognitiveDifficulties", currentData.learningCognitiveDifficulties)
		formMethods.setValue("mentalHealthConcerns", currentData.mentalHealthConcerns)
		formMethods.setValue("physicalDifficulties", currentData.physicalDifficulties)
		formMethods.setValue("accessibilityRequirements", currentData.accessibilityRequirements)
		formMethods.setValue("riskOfFalls", currentData.riskOfFalls)
		formMethods.setValue("allergies", currentData.allergies)
		formMethods.setValue("medicalHistory", currentData.medicalHistory)
		formMethods.setValue("palliativeSupportCare", currentData.palliativeSupportCare)
		formMethods.setValue("hasLearningCognitiveDifficulties", currentData.hasLearningCognitiveDifficulties)
		formMethods.setValue("hasMentalHealthConcerns", currentData.hasMentalHealthConcerns)
		formMethods.setValue("hasPhysicalDifficulties", currentData.hasPhysicalDifficulties)
		formMethods.setValue("hasAccessibilityRequirements", currentData.hasAccessibilityRequirements)
		formMethods.setValue("hasRiskOfFalls", currentData.hasRiskOfFalls)
		formMethods.setValue("hasAllergies", currentData.hasAllergies)
		formMethods.setValue("hasMedicalHistory", currentData.hasMedicalHistory)
		formMethods.setValue("hasPalliativeSupportCare", currentData.hasPalliativeSupportCare)

		// set fields - supports
		formMethods.setValue("informalSupport", currentData.informalSupport)
		formMethods.setValue("formalSupport", currentData.formalSupport)
		formMethods.setValue("youngPersonGoodAt", currentData.youngPersonGoodAt)
		formMethods.setValue("workAndOrEducation", currentData.workAndOrEducation)
		formMethods.setValue("protectiveFactors", currentData.protectiveFactors)
		formMethods.setValue("identifiedSupport", currentData.identifiedSupport)
		formMethods.setValue("appointmentDetail", currentData.appointmentDetail)
		formMethods.setValue("reasonForNotAppropriate", currentData.reasonForNotAppropriate)
		formMethods.setValue("additionalSupportComment", currentData.additionalSupportComment)
		formMethods.setValue("YouthCarerSupport", currentData.YouthCarerSupport)
		formMethods.setValue("EarlyIntervention", currentData.EarlyIntervention)
		formMethods.setValue("NotAppropriateForHelpingMinds", currentData.NotAppropriateForHelpingMinds)

		// set fields - action plan
		formMethods.setValue("referralAccepted", currentData.referralAccepted)
		formMethods.setValue("bookSession", currentData.bookSession)
		formMethods.setValue("clientPackIssued", currentData.clientPackIssued)
		formMethods.setValue("referralAcceptedNote", currentData.referralAcceptedNote)
		formMethods.setValue("referralPending", currentData.referralPending)
		formMethods.setValue("pendingTeamMemberAvailability", currentData.pendingTeamMemberAvailability)
		formMethods.setValue("helpingMindsCaseReviewMeeting", currentData.helpingMindsCaseReviewMeeting)
		formMethods.setValue("intakeIncomplete", currentData.intakeIncomplete)
		formMethods.setValue("referralPendingNote", currentData.referralPendingNote)
		formMethods.setValue("referralIneligible", currentData.referralIneligible)
		formMethods.setValue("warmReferralRequired", currentData.warmReferralRequired)
		formMethods.setValue("referralIneligibleNote", currentData.referralIneligibleNote)
		formMethods.setValue("referredToLifeLinePhone", currentData.referredToLifeLinePhone)
		formMethods.setValue("preferredLocationOption", currentData.preferredLocationOption)
		formMethods.setValue("office", currentData.office)
		formMethods.setValue("preferredLocation", currentData.preferredLocation)
		formMethods.setValue("sentCounsellingForm", currentData.sentCounsellingForm)
		formMethods.setValue("additionalActionPlanComment", currentData.additionalActionPlanComment)
	}, [formData]) // eslint-disable-line react-hooks/exhaustive-deps
	React.useEffect(() => {
		prefillData()
	}, [prefillData])

	React.useEffect(() => {
		formMethods.setValue(
			"workerID",
			currentUser ? [{ ...currentUser, label: `${currentUser.firstName} ${currentUser.lastName}`, avatar: currentUser.avatarURL || "" }] : [],
		)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const [validationTriggered, setValidationTriggered] = React.useState<boolean>(false)
	const [incompleteSteps, setIncompleteSteps] = React.useState<number[]>([])

	// change side progress bar
	React.useEffect(() => {
		const pathElements = history.location.pathname.split("/")
		const lastValue = pathElements[pathElements.length - 1]
		if (firstLoad) {
			if (lastValue !== IntakeType.Youth) {
				history.push(intakeURLParser(`/portal/clients/${clientID}/intake/youth`, formID))
			}
			setFirstLoad(false)
			return
		}
		switch (lastValue) {
			case "parentGuardianDetails":
				setStep(1)
				break
			case "challengesForTheClient":
				setStep(2)
				break
			case "mentalHealthContext":
				setStep(3)
				break
			case "relationship":
				setStep(4)
				break
			case "workAndSchool":
				setStep(5)
				break
			case "presenceOfRiskConcerns":
				setStep(6)
				break
			case "supports":
				setStep(7)
				break
			case "actionPlan":
				setStep(8)
				break
			default:
				setStep(0)
				break
		}
	}, [history.location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

	const parseParentGuardianDetail = () => {
		const currentState = formMethods.getValues()

		// convert data
		const parentGuardianDetails: YouthParentGuardianDetailInput[] = []
		placeholder.forEach((i) => {
			// record formData
			parentGuardianDetails.push({
				firstName: currentState[`parentGuardianFirstName${i}`],
				lastName: currentState[`parentGuardianLastName${i}`],
				relationship: currentState[`parentGuardianRelationship${i}`],
				telephoneNumber: currentState[`parentGuardianTelephoneNumber${i}`],
				mobileNumber: currentState[`parentGuardianMobileNumber${i}`],
				dateOfBirth: currentState[`parentGuardianDateOfBirth${i}`],
				countryOfBirthID: currentState[`parentGuardianCountryOfBirthID${i}`],
				languageSpokenAtHomeID: currentState[`parentGuardianLanguageSpokenAtHomeID${i}`],
				requiresTranslator: currentState[`parentGuardianRequiredTranslator${i}`],
				culturallyAndLinguisticallyDiverse: currentState[`parentGuardianCulturallyAndLinguisticallyDiverse${i}`],
				aboriginalOrTorresStraitIslander: currentState[`parentGuardianAboriginalOrTorresStraitIslander${i}`],
			})
		})

		return parentGuardianDetails
	}

	const onSubmit = async (isOnSave: boolean) => {
		if (formIsCompleted || clientIntakeCreate.loading || clientLoading || !client) return
		const currentState = formMethods.getValues()

		// if not on save and does not pass the validation, terminate the process
		if (!isOnSave && !triggerValidation()) return

		// parse parent guardian details
		const parentGuardianDetails = parseParentGuardianDetail()
		// submit client intake
		const resp = await clientIntakeCreate.mutate({
			clientID,
			formID,
			type: IntakeType.Youth,
			onSave: isOnSave,
			content: {
				...currentState,
				parentGuardianDetails,
			},
		})

		if (resp.error || !resp.payload) return
		await fetchClient(clientID)
		setFormDirty(false)
		showToast("Youth Intake Document created successfully.", "positive")
		history.push(`/portal/clients/${clientID}#forms`)
	}

	const triggerValidation = () => {
		const currentState = formMethods.getValues()
		setValidationTriggered(true)
		const incompleteStep: number[] = []

		let isValid = validateReferrerDetail(currentState)
		if (!isValid) incompleteStep.push(0)

		isValid = validateParentGuardianDetails(currentState)
		if (!isValid) incompleteStep.push(1)

		isValid = validateChallengeForClient(currentState)
		if (!isValid) incompleteStep.push(2)

		isValid = validateMentalHealthCarerContext(currentState)
		if (!isValid) incompleteStep.push(3)

		isValid = validatePresenceOfRiskConcerns(currentState)
		if (!isValid) incompleteStep.push(6)

		isValid = validateActionPlan(currentState)
		if (!isValid) incompleteStep.push(8)

		setIncompleteSteps(incompleteStep)

		return incompleteStep.length === 0
	}

	const validateParentGuardianDetails = (currentState: any) => {
		let isValid = true

		placeholder.forEach((p) => {
			if (!isValid) return // skip process if the detail is already invalid

			if (currentState[`parentGuardianMobileNumber${p}`] === "" && currentState[`parentGuardianTelephoneNumber${p}`] === "") {
				isValid = false
				return
			}

			if (!!currentState[`parentGuardianTelephoneNumber${p}`] && !phoneNumberRegex.test(currentState[`parentGuardianTelephoneNumber${p}`])) {
				isValid = false
				return
			}

			if (
				// check first name
				currentState[`parentGuardianFirstName${p}`] === "" ||
				// check last name
				currentState[`parentGuardianLastName${p}`] === "" ||
				// check date of birth
				!currentState[`parentGuardianDateOfBirth${p}`] ||
				// check relationship
				!currentState[`parentGuardianRelationship${p}`] ||
				currentState[`parentGuardianRelationship${p}`].length === 0 ||
				// check country of birth
				!currentState[`parentGuardianCountryOfBirthID${p}`] ||
				currentState[`parentGuardianCountryOfBirthID${p}`].length === 0 ||
				// check language
				!currentState[`parentGuardianLanguageSpokenAtHomeID${p}`] ||
				currentState[`parentGuardianLanguageSpokenAtHomeID${p}`].length === 0 ||
				// check atsi
				!currentState[`parentGuardianAboriginalOrTorresStraitIslander${p}`] ||
				currentState[`parentGuardianAboriginalOrTorresStraitIslander${p}`].length === 0
			) {
				isValid = false
				return
			}
		})

		return isValid
	}

	const validateMentalHealthCarerContext = (currentState: any) => {
		if (currentState.hasDiagnoses && !currentState.youngPersonMentalHealth) return false
		return true
	}

	const container = css({
		width: "100%",
		height: "100%",
		display: "flex",
		opacity: clientIntakeCreate.loading || clientLoading ? 0.5 : 1,
		"@media only screen and (max-width: 1400px)": {
			flexDirection: "column",
			maxHeight: "100%",
			overflowY: "auto",
		},
	})

	const progressContainer = css({
		backgroundColor: "white",
		height: "fit-content",
		padding: "20px",
		marginRight: "25px",
		maxWidth: "340px",
		"@media only screen and (max-width: 1400px)": {
			marginBottom: "10px",
			marginRight: "0",
			maxWidth: "100%",
		},
	})

	const formContainer = css({
		display: "flex",
		alignItems: "center",
		height: "100%",
		position: clientIntakeCreate.loading || clientLoading ? "relative" : "unset",
	})

	const loadingIcon = css({
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	})

	const formInner = css({
		width: "100%",
		height: "100%",
		[`@media only screen and (max-width: 1500px)`]: {
			width: "90%",
		},
	})

	return (
		<div className={container}>
			<ZenCard className={progressContainer}>
				<ZenVerticalProgress
					incompleteSteps={incompleteSteps}
					saveForm={() => onSubmit(true)}
					isReadOnly={formIsCompleted}
					list={list}
					step={step}
					clientID={clientID}
					intakeType={IntakeType.Youth}
					formID={formID}
				/>
			</ZenCard>

			<ZenCard className={formContainer}>
				{(clientIntakeCreate.loading || clientLoading) && (
					<div className={loadingIcon}>
						<StyledSpinnerNext />
					</div>
				)}
				{clientIntakeCreate.error && <ErrorNotification messageOrPayload={clientIntakeCreate.payload} />}
				{countryData.error && <ErrorNotification messageOrPayload={countryData.payload} />}
				{languageData.error && <ErrorNotification messageOrPayload={languageData.payload} />}

				<FormProvider {...formMethods}>
					<div className={formInner}>
						<Switch>
							<Route
								exact
								path={routes.clients.client.intake.youth.root}
								render={() => (
									<Detail
										isReadOnly={formIsCompleted}
										validationTriggered={validationTriggered}
										clientID={clientID}
										intakeType={IntakeType.Youth}
										formID={formID}
									/>
								)}
							/>
							<Route
								exact
								path={routes.clients.client.intake.youth.presenceOfRiskConcerns}
								render={() => (
									<PresenceOfRiskConcerns
										isReadOnly={formIsCompleted}
										validationTriggered={validationTriggered}
										clientID={clientID}
										intakeType={IntakeType.Youth}
										formID={formID}
										countryOptions={countryOptions}
										languageOptions={languageOptions}
									/>
								)}
							/>
							<Route
								exact
								path={routes.clients.client.intake.youth.parentGuardianDetails}
								render={() => (
									<IntakeYouthParentGuardianDetail
										placeholder={placeholder}
										setPlaceholder={setPlaceholder}
										count={count}
										setCount={setCount}
										contactErrors={contactErrors}
										setContactErrors={setContactErrors}
										countryOptions={countryOptions}
										languageOptions={languageOptions}
										isReadOnly={formIsCompleted}
										validationTriggered={validationTriggered}
										clientID={clientID}
										formID={formID}
									/>
								)}
							/>
							<Route
								exact
								path={routes.clients.client.intake.youth.challengesForTheClient}
								render={() => (
									<ChallengesForClient
										isReadOnly={formIsCompleted}
										validationTriggered={validationTriggered}
										clientID={clientID}
										intakeType={IntakeType.Youth}
										formID={formID}
									/>
								)}
							/>
							<Route
								exact
								path={routes.clients.client.intake.youth.mentalHealthContext}
								render={() => (
									<IntakeYouthMentalHealthContext isReadOnly={formIsCompleted} validationTriggered={validationTriggered} clientID={clientID} formID={formID} />
								)}
							/>
							<Route
								exact
								path={routes.clients.client.intake.youth.relationship}
								render={() => (
									<IntakeYouthRelationship isReadOnly={formIsCompleted} validationTriggered={validationTriggered} clientID={clientID} formID={formID} />
								)}
							/>
							<Route
								exact
								path={routes.clients.client.intake.youth.workAndSchool}
								render={() => (
									<IntakeYouthWorkAndSchool isReadOnly={formIsCompleted} validationTriggered={validationTriggered} clientID={clientID} formID={formID} />
								)}
							/>
							<Route
								exact
								path={routes.clients.client.intake.youth.supports}
								render={() => (
									<Supports
										isReadOnly={formIsCompleted}
										validationTriggered={validationTriggered}
										clientID={clientID}
										intakeType={IntakeType.Youth}
										formID={formID}
									/>
								)}
							/>
							<Route
								exact
								path={routes.clients.client.intake.youth.actionPlan}
								render={() => (
									<ActionPlan
										isReadOnly={formIsCompleted}
										clientID={clientID}
										intakeType={IntakeType.Youth}
										formID={formID}
										validationTriggered={validationTriggered}
										onSubmit={() => onSubmit(false)}
									/>
								)}
							/>
						</Switch>
					</div>
				</FormProvider>
			</ZenCard>
			<Prompt
				when={formDirty}
				message={() => {
					if (history.location.pathname.startsWith(routes.withID(clientID, routes.clients.client.intake.youth.root))) return true
					return "You have unsaved changes, are you sure you want to leave?"
				}}
			/>
		</div>
	)
}
