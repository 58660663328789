import * as React from "react"
import { useStyletron } from "baseui"
import { CheckboxOverrides } from "baseui/checkbox"
import { Value } from "baseui/select"
import { LabelLarge, LabelMedium, LabelSmall } from "baseui/typography"
import moment from "moment-timezone"
import { useFormContext } from "react-hook-form"
import { useHistory } from "react-router-dom"

import { ZenTheme } from "../../../../themeOverrides"
import { ClientRelationshipType, IntakeType, minimumAgeMonths } from "../../../../types/enums"
import { atsiOption } from "../../../../types/types"
import { CancelAndSaveButtons } from "../../../cancelSaveButtons"
import { ZenCheckbox } from "../../../zenComponents/zenCheckboxList"
import { ZenInput, ZenTextArea } from "../../../zenComponents/zenInput"
import { ZenSelect } from "../../../zenComponents/zenSelectBox"
import { ZenDatePicker } from "../../../zenComponents/zenTime"
import { intakeURLParser } from "../adult/intakeAdultBaseForm"
import { IntakeScrollDiv } from "../intakeComponent"
import { CommonIntakeFormData } from "./detail"
import { ErrorFieldTracker } from "../../../forms/errorFieldTracker"

export const PresenceOfRiskConcerns = (props: CommonIntakeFormData) => {
	const { clientID, intakeType, formID, isReadOnly, validationTriggered, languageOptions, countryOptions } = props

	const [css] = useStyletron()
	const history = useHistory()

	const { watch, trigger, control, errors, getValues } = useFormContext()

	const hasSuicidalIdeationSelfHarm = watch("hasSuicidalIdeationSelfHarm")
	const hasFinancialHardship = watch("hasFinancialHardship")
	const hasUnstableHousing = watch("hasUnstableHousing")
	const hasViolenceAbuse = watch("hasViolenceAbuse")
	const hasAODIssue = watch("hasAODIssue")

	// for youth intake only
	const hasSharedCustodySecondaryParent = watch("hasSharedCustodySecondaryParent")
	const hasSharedParentalResponsibilitySecondaryParent = watch("hasSharedParentalResponsibilitySecondaryParent")
	const hasSharedParentalResponsibilitySecondaryParentNotAwareOfService = watch("hasSharedParentalResponsibilitySecondaryParentNotAwareOfService")
	const hasSingleParentalResponsibilityProceedWithoutOtherConsent = watch("hasSingleParentalResponsibilityProceedWithoutOtherConsent")
	const hasOtherFamilyArrangement = watch("hasOtherFamilyArrangement")

	const [hasContactError, setHasContactError] = React.useState<boolean>(false)

	const validation = React.useCallback(async () => {
		setHasContactError(false)
		const checkList: string[] = []

		// generate checkList
		if (hasSuicidalIdeationSelfHarm) checkList.push("suicidalIdeationSelfHarm")
		if (hasFinancialHardship) checkList.push("financialHardship")
		if (hasUnstableHousing) checkList.push("unstableHousing")
		if (hasViolenceAbuse) checkList.push("violenceAbuse")
		if (hasAODIssue) checkList.push("aodIssue")

		// skip check if checking list is empty
		if (checkList.length === 0) return true

		const isValid = await trigger(checkList)
		return isValid
	}, [hasAODIssue, hasFinancialHardship, hasSuicidalIdeationSelfHarm, hasUnstableHousing, hasViolenceAbuse, trigger])

	React.useEffect(() => {
		if (validationTriggered) validation()
	}, [validationTriggered, validation])

	const onSubmit = async () => {
		const isValid = await validation()
		if (!isValid) return
		nextPage()
	}

	const nextPage = () => {
		switch (intakeType) {
			case IntakeType.Adult:
				history.push(intakeURLParser(`/portal/clients/${clientID}/intake/adult/emergencyContactDetails`, formID))
				break
			case IntakeType.Youth:
				history.push(intakeURLParser(`/portal/clients/${clientID}/intake/youth/supports`, formID))
				break
		}
	}

	const additionFields = () => {
		switch (intakeType) {
			case IntakeType.Adult:
				return (
					<>
						<ZenCheckbox
							disabled={isReadOnly}
							label="Refer to HelpingMinds case review meeting"
							formName="referHelpingMindsCaseReview"
							formRef={control}
							overrides={checkBoxStyle}
						/>
						<ZenCheckbox disabled={isReadOnly} label="Warm referral required" formName="referralRequired" formRef={control} overrides={checkBoxStyle} />
						<ZenCheckbox
							label="Referred to LifeLine Phone: 13 11 14"
							formName="referredToLifeLine"
							formRef={control}
							disabled={isReadOnly}
							overrides={checkBoxStyle}
						/>
					</>
				)
			// case IntakeType.Youth:
			// 	return (
			// 		<>
			// 			<LabelLarge>What is the current guardian / living arrangements for the client</LabelLarge>
			// 			<ZenCheckbox
			// 				label="Full custody / Sole guardian"
			// 				formName="fullCustodySoleGuardian"
			// 				formRef={control}
			// 				disabled={isReadOnly}
			// 				overrides={checkBoxStyle}
			// 			/>
			// 			<ZenCheckbox
			// 				label="Shared Custody / Secondary parent aware and consenting to services"
			// 				formName="hasSharedCustodySecondaryParent"
			// 				formRef={control}
			// 				disabled={isReadOnly}
			// 				overrides={checkBoxStyle}
			// 			/>
			// 			{hasSharedCustodySecondaryParent && (
			// 				<>
			// 					<div className={group}>
			// 						<ZenInput
			// 							disabled={isReadOnly}
			// 							label="First Name*"
			// 							nameRef={`sharedParentSecondaryGuardianFirstName`}
			// 							formRef={control}
			// 							required
			// 							inputError={errors[`sharedParentSecondaryGuardianFirstName`]}
			// 						/>
			// 						<ZenInput
			// 							disabled={isReadOnly}
			// 							marginLeft="10px"
			// 							label="Last Name*"
			// 							nameRef={`sharedParentSecondaryGuardianLastName`}
			// 							formRef={control}
			// 							required
			// 							inputError={errors[`sharedParentSecondaryGuardianLastName`]}
			// 						/>
			// 					</div>
			// 					<ZenSelect
			// 						disabled={isReadOnly}
			// 						label="Relationship"
			// 						formName={`sharedParentSecondaryGuardianRelationship`}
			// 						formRef={control}
			// 						formRules={{
			// 							validate: {
			// 								required: (value: Value) => (!!value && value.length > 0) || "Relationship is required",
			// 							},
			// 						}}
			// 						inputError={errors[`sharedParentSecondaryGuardianRelationship`]}
			// 						clearable={false}
			// 						options={Object.values(ClientRelationshipType).map((r) => ({ id: r, label: r }))}
			// 					/>
			// 					<div className={group}>
			// 						{hasContactError && (
			// 							<LabelMedium color={ZenTheme.colors.warning600} marginTop="10px" marginLeft="8px">
			// 								- One phone number is required
			// 							</LabelMedium>
			// 						)}
			// 					</div>
			// 					<ZenInput
			// 						// Use generic input to allow for more flexible usage, such as a note about the number.
			// 						// Not required because details could be unknown
			// 						disabled={isReadOnly}
			// 						label="Telephone Number"
			// 						nameRef={`sharedParentSecondaryGuardianTelephoneNumber`}
			// 						formRef={control}
			// 						inputError={errors[`sharedParentSecondaryGuardianTelephoneNumber`]}
			// 					/>
			// 					<ZenInput
			// 						// Use generic input to allow for more flexible usage, such as a note about the number.
			// 						// Not required because details could be unknown
			// 						disabled={isReadOnly}
			// 						label="Mobile Number"
			// 						nameRef={`sharedParentSecondaryGuardianMobileNumber`}
			// 						formRef={control}
			// 						inputError={errors[`sharedParentSecondaryGuardianMobileNumber`]}
			// 					/>
			// 					<ZenDatePicker
			// 						disabled={isReadOnly}
			// 						label="Date of Birth"
			// 						nullDefaultValue
			// 						formName={`sharedParentSecondaryGuardianDateOfBirth`}
			// 						inputError={errors[`sharedParentSecondaryGuardianDateOfBirth`]}
			// 						formRef={control}
			// 						formRules={{
			// 							required: "Date of birth is required",
			// 						}}
			// 						minDate={new Date("1900-01-01")}
			// 						maxDate={moment().subtract(minimumAgeMonths, "month").toDate()}
			// 					/>
			// 					<ZenSelect
			// 						disabled={isReadOnly}
			// 						label="Country of Birth"
			// 						formName={`sharedParentSecondaryGuardianCountryOfBirthID`}
			// 						formRef={control}
			// 						formRules={{
			// 							validate: {
			// 								required: (value: Value) => (!!value && value.length > 0) || "Country of birth is required",
			// 							},
			// 						}}
			// 						inputError={errors[`sharedParentSecondaryGuardianCountryOfBirthID`]}
			// 						clearable={false}
			// 						options={countryOptions || []}
			// 					/>
			// 					<ZenSelect
			// 						disabled={isReadOnly}
			// 						label="Language Spoken at Home"
			// 						formName={`sharedParentSecondaryGuardianLanguageSpokenAtHomeID`}
			// 						formRef={control}
			// 						formRules={{
			// 							validate: {
			// 								required: (value: Value) => (!!value && value.length > 0) || "Language spoken at home is required",
			// 							},
			// 						}}
			// 						inputError={errors[`sharedParentSecondaryGuardianLanguageSpokenAtHomeID`]}
			// 						clearable={false}
			// 						options={languageOptions || []}
			// 					/>
			// 					<div className={group}>
			// 						<ZenCheckbox
			// 							disabled={isReadOnly}
			// 							label="Translator Required"
			// 							formName={`sharedParentSecondaryGuardianRequiresTranslator`}
			// 							formRef={control}
			// 							width="100%"
			// 						/>
			// 						<ZenCheckbox
			// 							disabled={isReadOnly}
			// 							label="Identify as CaLD"
			// 							formName={`sharedParentSecondaryGuardianCulturallyAndLinguisticallyDiverse`}
			// 							formRef={control}
			// 							checked={false}
			// 							width="100%"
			// 						/>
			// 					</div>
			// 					<ZenSelect
			// 						disabled={isReadOnly}
			// 						label="Identify as ATSI"
			// 						formName={`sharedParentSecondaryGuardianAboriginalOrTorresStraitIslander`}
			// 						formRef={control}
			// 						inputError={errors[`sharedParentSecondaryGuardianAboriginalOrTorresStraitIslander`]}
			// 						formRules={{
			// 							validate: {
			// 								required: (value: Value) => (!!value && value.length > 0) || "ATSI is required",
			// 							},
			// 						}}
			// 						clearable={false}
			// 						options={Object.entries(atsiOption).map((a) => ({ label: a[1], id: a[0] }))}
			// 					/>
			// 					<ZenCheckbox
			// 						disabled={isReadOnly}
			// 						label="Dual parent family / Single living parent"
			// 						formName={`dualParentFamilySingleLivingParent`}
			// 						formRef={control}
			// 						checked={false}
			// 						width="100%"
			// 					/>

			// 					<ZenCheckbox
			// 						disabled={isReadOnly}
			// 						label="Shared Parental responsibility / Secondary parent aware and consenting to service"
			// 						formName={`hasSharedParentalResponsibilitySecondaryParent`}
			// 						formRef={control}
			// 						checked={false}
			// 						width="100%"
			// 					/>
			// 					{hasSharedParentalResponsibilitySecondaryParent && (
			// 						<LabelSmall marginLeft="35px" color="rgba(0,0,0,0.4)">{`( will need to provide the Court/Parenting Order )`}</LabelSmall>
			// 					)}

			// 					<ZenCheckbox
			// 						disabled={isReadOnly}
			// 						label="Shared Parental responsibility / Secondary parent not aware of service"
			// 						formName={`hasSharedParentalResponsibilitySecondaryParentNotAwareOfService`}
			// 						formRef={control}
			// 						checked={false}
			// 						width="100%"
			// 					/>
			// 					{hasSharedParentalResponsibilitySecondaryParentNotAwareOfService && (
			// 						<LabelSmall marginLeft="35px" color="rgba(0,0,0,0.4)">{`( will need to provide the Court/Parenting Order )`}</LabelSmall>
			// 					)}

			// 					<ZenCheckbox
			// 						disabled={isReadOnly}
			// 						label="Single Parental responsibility / I wish to proceed without other consent"
			// 						formName={`hasSingleParentalResponsibilityProceedWithoutOtherConsent`}
			// 						formRef={control}
			// 						checked={false}
			// 						width="100%"
			// 					/>
			// 					{hasSingleParentalResponsibilityProceedWithoutOtherConsent && (
			// 						<LabelSmall marginLeft="35px" color="rgba(0,0,0,0.4)">{`( will need to provide the Court/Parenting Order )`}</LabelSmall>
			// 					)}

			// 					<ZenCheckbox
			// 						disabled={isReadOnly}
			// 						label="Other family arrangement"
			// 						formName={`hasOtherFamilyArrangement`}
			// 						formRef={control}
			// 						checked={false}
			// 						width="100%"
			// 					/>
			// 					{hasOtherFamilyArrangement && (
			// 						<LabelSmall marginLeft="35px" color="rgba(0,0,0,0.4)">{`( will need to provide the Court/Parenting Order as applicable )`}</LabelSmall>
			// 					)}

			// 					<ZenTextArea
			// 						getValues={getValues}
			// 						disabled={isReadOnly}
			// 						marginLeft="8px"
			// 						label="Additional Comment"
			// 						formRef={control}
			// 						nameRef="singleParentAdditionalComment"
			// 					/>
			// 					<ZenCheckbox
			// 						disabled={isReadOnly}
			// 						label="Refer to HelpingMinds case review meeting"
			// 						formName={`referHelpingMindsCaseReview`}
			// 						formRef={control}
			// 						checked={false}
			// 						width="100%"
			// 					/>
			// 				</>
			// 			)}
			// 		</>
			// 	)
		}

		return null
	}

	const container = css({
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		backgroundColor: "white",
		flexDirection: "row",
	})
	const formStyle = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		paddingBottom: "25px",
		paddingTop: "25px",
	})
	const body = css({
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "100%",
		minHeight: 0,
	})
	const checkBoxStyle: CheckboxOverrides = {
		Root: {
			style: () => ({
				marginTop: "15px",
			}),
		},
	}
	const group = css({
		display: "flex",
		alignItems: "center",
	})
	return (
		<div className={container}>
			<div className={formStyle}>
				<div className={body}>
					<LabelLarge>{intakeType === IntakeType.Adult ? "Eligibility Screen / Presence of Risk Concerns" : "Presence of Risk Concerns"}</LabelLarge>
					<IntakeScrollDiv>
						<ZenCheckbox label="Suicidal ideation/Self Harm" formRef={control} formName="hasSuicidalIdeationSelfHarm" disabled={isReadOnly} />
						{hasSuicidalIdeationSelfHarm && (
							<>
								<ZenCheckbox
									disabled={isReadOnly}
									marginLeft="16px"
									marginTop="10px"
									label="within the last 3 month"
									formName="withinLastThreeMonth"
									formRef={control}
								/>
								<ZenTextArea
									disabled={isReadOnly}
									label="Comments"
									nameRef="suicidalIdeationSelfHarm"
									formRef={control}
									formRules={{ required: "Details are required" }}
									inputError={errors.suicidalIdeationSelfHarm}
									placeholder="Enter details about Suicidal ideation/Self Harm"
									initialHeight={180}
									getValues={getValues}
								/>
							</>
						)}
						{intakeType === IntakeType.Adult && (
							<>
								<ZenCheckbox label="Financial Hardship" formName="hasFinancialHardship" formRef={control} disabled={isReadOnly} overrides={checkBoxStyle} />

								{hasFinancialHardship && (
									<ZenTextArea
										disabled={isReadOnly}
										label="Comments"
										nameRef="financialHardship"
										formRef={control}
										formRules={{ required: "Details are required" }}
										inputError={errors.financialHardship}
										placeholder="Enter details about Financial Hardship"
										initialHeight={180}
										getValues={getValues}
									/>
								)}
							</>
						)}
						<ZenCheckbox
							disabled={isReadOnly}
							formRef={control}
							label={`Unstable Housing ${intakeType === IntakeType.Youth ? "/ parenting situation" : ""}`}
							formName="hasUnstableHousing"
							overrides={checkBoxStyle}
						/>
						{hasUnstableHousing && (
							<ZenTextArea
								disabled={isReadOnly}
								label="Comments"
								nameRef="unstableHousing"
								formRef={control}
								formRules={{ required: "Details are required" }}
								inputError={errors.unstableHousing}
								placeholder="Enter details about Unstable Housing"
								initialHeight={180}
								getValues={getValues}
							/>
						)}
						<ZenCheckbox disabled={isReadOnly} formName="hasViolenceAbuse" formRef={control} label="Violence/Abuse" overrides={checkBoxStyle} />
						{hasViolenceAbuse && (
							<ZenTextArea
								disabled={isReadOnly}
								label="Comments"
								nameRef="violenceAbuse"
								formRef={control}
								formRules={{ required: "Details are required" }}
								inputError={errors.violenceAbuse}
								placeholder="Enter details about Violence/Abuse"
								initialHeight={180}
								getValues={getValues}
							/>
						)}
						<ZenCheckbox disabled={isReadOnly} label="AOD Issues" formRef={control} formName="hasAODIssue" overrides={checkBoxStyle} />
						{hasAODIssue && (
							<ZenTextArea
								disabled={isReadOnly}
								label="Comments"
								nameRef="aodIssue"
								formRef={control}
								formRules={{ required: "Details are required" }}
								inputError={errors.aodIssue}
								placeholder="Enter details about AOD Issues"
								initialHeight={180}
								getValues={getValues}
							/>
						)}
						<ZenTextArea
							disabled={isReadOnly}
							placeholder="Enter details"
							label="Are there any others residing in the home that may need support?"
							nameRef="otherResiding"
							formRef={control}
							initialHeight={180}
							getValues={getValues}
						/>
						{additionFields()}
					</IntakeScrollDiv>
				</div>
				<ErrorFieldTracker errorIDs={Object.keys(errors)} />
				{!isReadOnly && <CancelAndSaveButtons cancelLabel="Back" cancelFn={history.goBack} saveLabel="Next" saveFn={onSubmit} />}
			</div>
		</div>
	)
}
