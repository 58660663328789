import "./App.css"
import "./styles.scss"

import React, { useState, useEffect } from "react" // Import useEffect
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import { BaseProvider, useStyletron } from "baseui"

import { ClientContextProvider } from "react-fetching-library"
import MetaTags from "react-meta-tags"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { Client as Styletron } from "styletron-engine-atomic"
import { Provider as StyletronProvider } from "styletron-react"

import { Client } from "./api/client"
import { AuthContainer } from "./controllers/auth"
import { loadIcons } from "./helpers/loadicons"
import { routes } from "./routes"
import { ZenTheme, ZenDarkTheme } from "./themeOverrides" // Import your themes

import Portal from "./pages/portal"
import Login from "./pages/login"
import { base_host as host } from "./host"

const engine = new Styletron()

loadIcons()

Sentry.init({
	dsn: host.sentry.dsn,
	integrations: [new Integrations.BrowserTracing()],
	environment: process.env.NODE_ENV,

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 0.25,
})

Sentry.setTag("build_version", `v${process.env.REACT_APP_VERSION}`)

const Routes = () => {
	const [css] = useStyletron()
	const routeStyle = css({
		width: "100%",
		height: "100%",
		fontFamily: "'Open Sans', system-ui, 'Helvetica Neue', Helvetica, Arial, sans-serif",
	})

	return (
		<div className={routeStyle}>
			<Switch>
				<Route path={routes.root} exact component={Login} />
				<Route path={routes.portal} component={Portal} />
			</Switch>
		</div>
	)
}

const App = () => {
	// State for dark mode
	const [darkMode, setDarkMode] = useState(false)

	// Toggle dark mode
	const toggleDarkMode = () => setDarkMode(!darkMode)

	// Add or remove dark mode class on the body element
	useEffect(() => {
		if (darkMode) {
			document.body.classList.add("dark-mode")
		} else {
			document.body.classList.remove("dark-mode")
		}
	}, [darkMode]) // This effect runs whenever darkMode changes

	return (
		<StyletronProvider value={engine}>
			<BaseProvider 
			// theme={darkMode ? ZenDarkTheme : ZenTheme}
				theme={ZenTheme}
				overrides={{
					AppContainer: {
						style: {
							height: "100%",
						},
					},
				}}
			>
				<ClientContextProvider client={Client}>
					<MetaTags>
						<title>HelpingMinds - Zen</title>
						<meta name="viewport" content="width=device-width, initial-scale=1.0" />
						<meta id="meta-description" name="description" content="Some description." />
						<meta id="og-title" property="og:title" content="MyApp" />
						<meta id="og-image" property="og:image" content="path/to/image.jpg" />
					</MetaTags>
					<AuthContainer.Provider>
						<Sentry.ErrorBoundary showDialog>
							<Router>
								{/* Dark mode toggle button in the navbar */}
								{/* <div style={{ position: "absolute", top: 10, left: 10 }}>
									<button onClick={toggleDarkMode}>
										{darkMode ? "Light Mode" : "Dark Mode"}
									</button>
								</div> */}
								<Routes />
							</Router>
						</Sentry.ErrorBoundary>
					</AuthContainer.Provider>
				</ClientContextProvider>
			</BaseProvider>
		</StyletronProvider>
	)
}

export default App
